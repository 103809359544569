

const MODE = {
  production : 'production',
  devel : 'devel'
}

const configuration = {}

configuration[MODE.production] = {
  apiUrl : 'https://verkstaderna-api.candybanana.se',
  notificationReminderTimeoutInSeconds : (60 * 60 * 24)
}

configuration[MODE.devel] = {
  apiUrl : 'https://dev-verkstaderna-api.candybanana.se',
  notificationReminderTimeoutInSeconds : 3600
}

/** set mode and export */
const mode = __DEV__ ? MODE.production : MODE.production;
const settings = configuration[mode];

export { settings, mode }