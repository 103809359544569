import React, { useContext, useEffect, useState } from 'react';

import { CheckBox, Modal, ScrollView, Text, View, Pressable } from 'react-native';

import { TranslatedText, TranslatedData } from '../components/TranslatedText'

import { useTheme } from '../providers/ThemeContext';
import { useTranslation } from '../providers/translate';
import { restApi } from '../providers/restApi';

let app = require('../../app.json');

import { SettingsContext } from '../providers/SettingsContext';
import { FollowButton } from '../components/FollowButton';

import { HeaderContext } from '../components/Header';

const languageNames = {
  sv : 'Svenska',
  en : 'English'
}

const languages = [
  { title: languageNames['sv'], id: 'sv' },
  { title: languageNames['en'], id: 'en' }
]

const SettingsScreen = (props) => {

  const { translate } = useTranslation();
  const settingsContext = useContext(SettingsContext);
  const headerContext = useContext(HeaderContext);

  const theme = useTheme();  
  const [languageDialogVisible , setLanguageDialogVisible] = useState(false);
  const [language, setLanguage] = useState(settingsContext.state.language || 'sv');
  const [tags, setTags] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [facilities, setFacilities] = useState([]);

  const { user } = settingsContext.state;
  const version = app.expo.version;

  useEffect(() => {
    headerContext.setOptions({ title: translate('general.settings') });    
  }, []);

  let tagsFollowed =  (user && user.tagsFollowed) ? user.tagsFollowed : [];
  let organisationsFollowed = (user && user.organisationsFollowed) ? user.organisationsFollowed : [];
  let facilitiesFollowed = (user && user.facilitiesFollowed) ? user.facilitiesFollowed : [];

  useEffect(() => {
    (async () => {
      let should = tagsFollowed.map(tag => ({ term: { "name.keyword": tag } }))
      let tagsQuery = { query: { bool: { should } } }
      let tags = await restApi.post('/tags/search', tagsQuery).then(r => r.Hits);
      setTags(tags);
    })();
  }, [tagsFollowed])

  useEffect(() => {
    (async () => {
      setLanguage(settingsContext.state.language)
    })();
  }, [settingsContext.state.language]);

  useEffect(() => {
    (async () => {
      let should = organisationsFollowed.map(tag => ({ term: { "id.keyword": tag } }))
      let organisationsQuery = { query: { bool: { should } } }
      let organisations = await restApi.post('/organisations/search', organisationsQuery).then(r => r.Hits);
      setOrganisations(organisations);
    })();
  }, [organisationsFollowed])

  useEffect(() => {
    (async () => {
      let should = facilitiesFollowed.map(tag => ({ term: { "id.keyword": tag } }))
      let facilitiesQuery = { query: { bool: { should } } }
      let facilities = await restApi.post('/facilities/search', facilitiesQuery).then(r => r.Hits);
      setFacilities(facilities);
    })();
  }, [facilitiesFollowed])

  const setPreferedLanguage = () => {
    setLanguageDialogVisible(false); 
    settingsContext.dispatch({ type: 'setLanguage', value: language })
  }

  const chooseLanguage = (language) => {
    setLanguage(language.id);
  }
  
  return (
    <ScrollView style={{ backgroundColor: theme.colors.contentBackgroundColor, height : '100%', flex : 1, display : 'flex' }} >
      <View>

        <View style={[theme.style.contentBlock]}>
          <View style={[theme.style.content, theme.style.dropShadowIOS]}>
            <View style={[theme.style.listItem, { alignItems: 'center', justifyContent: 'space-between' }]}>
              <TranslatedText style={[theme.style.defaultText]} id="general.language" />
              <Pressable 
                type='clear'
                style={[theme.style.button]}
                onPress={() => { setLanguageDialogVisible(true) }}
              >
                <Text style={theme.style.buttonText}>{languageNames[settingsContext.state.language]}</Text>
              </Pressable>
            </View>
          </View>
        </View>

        {tagsFollowed.length > 0 && (
          <View style={[theme.style.contentBlock]}>
            <View style={[theme.style.sectionHeader]}>
              <TranslatedText style={[theme.style.defaultText, theme.style.defaultSectionHeaderText]} id="general.followed_tags" />
            </View>
            <View style={[theme.style.content, theme.style.dropShadowIOS]}>
              {tags.map(tag => {
                return (
                  <View key={tag.id} style={[theme.style.listItem, theme.style.listItemNarrow, { alignItems: 'center', justifyContent: 'space-between' }]}>
                    <TranslatedData style={[theme.style.defaultText, { maxWidth : '70%'}]} prefix='#' data={tag} id="title" />
                    <FollowButton type='tag' id={tag.name} />
                  </View>
                );
              })}
            </View>
          </View>
        )}

        {organisationsFollowed.length > 0 && (
          <View style={[theme.style.contentBlock]}>
            <View style={[theme.style.sectionHeader]}>
              <TranslatedText style={[theme.style.defaultText, theme.style.defaultSectionHeaderText]} id="general.followed_organisations" />
            </View>
            <View style={[theme.style.content, theme.style.dropShadowIOS]}>
              {organisations.map(organisation => {
                return (
                  <View key={organisation.id} style={[theme.style.listItem, theme.style.listItemNarrow, { alignItems: 'center', justifyContent: 'space-between' }]}>
                    <TranslatedData style={[theme.style.defaultText, { maxWidth : '70%'}]} data={organisation} id="title" />
                    <FollowButton type='organisation' id={organisation.id} />
                  </View>
                );
              })}
            </View>
          </View>
        )}

        {facilitiesFollowed.length > 0 && (
          <View style={[theme.style.contentBlock]}>
            <View style={[theme.style.sectionHeader]}>
              <TranslatedText style={[theme.style.defaultText, theme.style.defaultSectionHeaderText]} id="general.followed_facilities" />
            </View>
            <View style={[theme.style.content, theme.style.dropShadowIOS]}>
              {facilities.map(facility => {
                return (
                  <View key={facility.id} style={[theme.style.listItem, theme.style.listItemNarrow, { alignItems: 'center', justifyContent: 'space-between' }]}>
                    <TranslatedData style={[theme.style.defaultText, { maxWidth : '70%'}]} data={facility} id="title" />
                    <FollowButton type='facility' id={facility.id} />
                  </View>
                );
              })}
            </View>
          </View>
        )}

      </View>
      
      <View style={{ display : 'flex', marginTop : 10, justifyContent : 'center', alignItems : 'center'}}>
        <Text style={[theme.style.defaultText]}>Version {version}</Text>          
      </View>

      <Modal
        animationType="slide"
        transparent={true}
        visible={languageDialogVisible}
        onRequestClose={() => {
          setLanguageDialogVisible(false);
        }}
      >
        <View style={[{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)'}]}>
          <View style={[{ width: 300, borderRadius: 20, padding: 10, backgroundColor: '#fff'}]}>
            <View style={theme.style.modalHeader}>
              <Text style={[theme.style.defaultText, { color: theme.colors.defaultText }]}>{translate('general.language')}</Text>
            </View>
            <View style={[theme.style.modalBody]}>
              {languages.map((l) => {
                return (
                  <View key={l.id} style={[theme.style.listItem, { alignItems: 'center', justifyContent: 'start' }]}>
                    <CheckBox
                      value={language === l.id}
                      onValueChange={e => chooseLanguage(l)}
                      style={theme.style.checkbox}
                    />
                    <Pressable onPress={e => chooseLanguage(l)}>
                      <Text style={{ marginLeft: 10}}>{languageNames[l.id]}</Text>
                    </Pressable>
                  </View>
                )
              })}
            </View>
            <View style={[theme.style.modalFooter]}>
              <Pressable style={theme.style.button} onPress={setPreferedLanguage} >
                <Text style={theme.style.buttonText}>{translate('general.ok')}</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </ScrollView>
  )
}

export { SettingsScreen }