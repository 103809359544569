import { useContext } from 'react';

import { SettingsContext } from '../providers/SettingsContext';

import { sv } from './translations/sv';
import { en } from './translations/en';

import loadash from 'lodash';

const _get = loadash.get;

const translations = {
  sv,
  en
}

export const useTranslation = () => {

  const { state } = useContext(SettingsContext);
  const { language } = state;

  const translate = (key) => {
    if(translations[language]) {
      return(_get(translations[language], key, key))
    } else {
      return(key)
    }
  }

  return({ translate })

}


export const useTranslatedData = () => {

  const { state } = useContext(SettingsContext);
  const { language } = state;
  
  /**
   * 
   * @param {*} data 
   * @param {*} property 
   * @returns 
   */
  const translate = (data, property, fallbackProperty) => {
    const defaultValue = data[property] || data[fallbackProperty];
    if(language === 'sv' || !data.translations) {
      return(defaultValue);
    } else if(data.translations) {
      if(!data.translations[language])
        return(defaultValue);
      var translation = data.translations[language][property];
      var translationFallback = data.translations[language][fallbackProperty];      
      return(translation || translationFallback || defaultValue);
    }
  }

  return({ translate })

}

