import React from 'react';
import { ActivityIndicator, Dimensions, View, Image, StyleSheet, Pressable } from 'react-native';
import { EventDate } from './EventDate';
//import { LinearGradient } from 'expo-linear-gradient';

import { useTheme } from '../providers/ThemeContext';
import { TranslatedData } from './TranslatedText';

const emptyImage = require('../assets/event.jpg');

const FeaturedEvent = ({ event, onTagClicked, viewEvent, style }) => {

  const windowWidth = Dimensions.get('window').width;
  const theme = useTheme();
  
  const browseByTag = (tag) => {
    if (typeof (onTagClicked) === 'function') onTagClicked(tag)
  }

  const navigateToEvent = (event) => {
    if (typeof (viewEvent) === 'function') viewEvent(event)
  }

  const w = 738;
  const h = 738 * 0.7;

  const styles = StyleSheet.create({
    eventHeader: {
      minHeight: 200,
      width: w,
      height: h,
      position: 'absolute',
      zIndex: 1,
      borderRadius: 20,
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',      
    },    
    metaInfo:{
      flexDirection:'row',
      justifyContent:'space-between',
      alignItems:'flex-start',
    },
    tagList: {
      flex:1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    
  })
  
  let media = (event.media || []);
  if (media.length === 0)
    media = [{}];

  const scaledImage = (img, w, h) => {
    return(`${img.replace('media/', 'image/')}/${w}/${h}`)
  }

  let backgroundSource = media[0].downloadUrl ? { uri: scaledImage(media[0].downloadUrl, 600, 600) } : emptyImage;

  return (
    <Pressable onPress={e => navigateToEvent(event)}>
      <View style={[theme.style.eventCard, style]} >
        <div style={styles.eventHeader}>
          <div style={{ borderRadius: 20, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%', padding: 15, paddingBottom: 30, background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%)'}}>
            <View style={styles.metaInfo}>
              <View style={styles.tagList}>
                {(event.tags || []).map((tag, index) => {
                  return (
                    <Pressable key={index} onPress={e => browseByTag(tag)} style={[theme.style.tag, theme.style.tagSmall, theme.style.dropShadow,{marginBottom:7.5}]} >
                      <TranslatedData key={index} style={[theme.style.tagSmallText]} prefix="#" data={tag} id="name" />
                    </Pressable>
                  )
                })}
              </View>
              <EventDate fullDate={event.date} style={[styles.eventDate]} onPress={() => { alert('pressed') }}>{event.date.start.stringValue}</EventDate>
            </View>
            <View>
              <TranslatedData style={[theme.style.defaultTitleText, theme.style.eventCardTitle]} data={event} id="title" />
              <TranslatedData style={[theme.style.eventCardTagline]} data={event} id="tagLine" />
            </View>
          </div>
        </div>
        <View style={[{ width: w, height: h }]}>
          <Image
            source={backgroundSource}
            style={{
              height: '100%',
              borderRadius: 20,
            }}
            resizeMode='cover'
            PlaceholderContent={<ActivityIndicator size="large" color={theme.colors.text}/>}
          />
        </View>
      </View>
    </Pressable>
  )
}

export { FeaturedEvent }