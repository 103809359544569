import React, { useContext, useEffect } from 'react';

import { DataObjectList } from '../components/DataObjectList';

import { View } from 'react-native';

import { TranslatedText } from '../components/TranslatedText';

import { useTranslation } from '../providers/translate';
import { HeaderContext } from '../components/Header';

const LinksScreen = () => {
  
  const { translate } = useTranslation();
  const headerContext = useContext(HeaderContext);

  useEffect(() => {
    headerContext.setOptions({ title: translate('general.links') });
  }, []);
  
  const infoBox = (
    <View>
      <TranslatedText id="general.links_view_info" />
    </View>
  )

  return(<DataObjectList infoBox={infoBox} dataType='links' labelSingular={translate('general.link')} labelPlural={translate('general.links')} />)
  
}

export { LinksScreen }