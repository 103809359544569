
import React from 'react';

import { View, ActivityIndicator, Image } from 'react-native';

import { useTheme } from '../providers/ThemeContext';
import { getScaledImageUrl } from '../providers/restApi';

const emptyEventImage = require('../assets/event.jpg');
const emptyOrganisationImage = require('../assets/organisation.jpg');
const emptyFacilityImage = require('../assets/facility.jpg');

const EventImage = (props) => {

  const { event, style, onPress, type } = props
  const { media } = event;
  const emptyImage = type == 'organisation' && emptyOrganisationImage || type == 'facility' && emptyFacilityImage || emptyEventImage
  const theme = useTheme();
  const width = props.width ? props.width : 34;
  const height = props.height ? props.height : 34

  const loadingIndicator = (
    <View style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <ActivityIndicator color={theme.colors.text} style={{ }}/>
    </View>
  )
  return (
    <>
      {media && media.length > 0 && (
        <View style={[{ overflow: 'hidden', borderRadius: 6, ...style }]}>
          {[...media].splice(0, 1).map((m, i) => {
            let scaledUrl = getScaledImageUrl([...media].splice(0, 1)[0], width, height, emptyImage);
            return (
              <Image key={i} onPress={onPress} source={scaledUrl} style={{ width, height }} PlaceholderContent={loadingIndicator} />
            );
          })}
        </View>
      )}
      {(!media || media.length === 0) && (
        <View style={[{ overflow: 'hidden', borderRadius: 6, display : 'flex', justifyContent : 'center', alignItems : 'center', ...style}]}>
          <Image onPress={onPress} source={emptyImage} style={{ width, height }} PlaceholderContent={loadingIndicator} />
        </View>
      )}
    </>
  )

}

export { EventImage }