import React, { useEffect, useState, useContext, useRef } from 'react';

import { ScrollView, ActivityIndicator, View, Pressable } from 'react-native';

import { FeaturedEvents } from '../components/FeaturedEvents';
import { UpcomingEvents } from '../components/UpcomingEvents';

import { EventImage } from '../components/EventImage';
import { TranslatedText, TranslatedData } from '../components/TranslatedText';

import { useTheme } from '../providers/ThemeContext';
import { SettingsContext } from '../providers/SettingsContext';
import { restApi } from '../providers/restApi';
import { getNextEventDate  } from '../utils';

import { useNavigate } from 'react-router-dom';

import { HeaderContext } from '../components/Header';
import { useTranslation } from '../providers/translate';

const HomeScreen = (props) => {
  
  const navigate = useNavigate();

  const theme = useTheme();
  const {translate} = useTranslation();
  const [popularTags, setPopularTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const upcomingList = useRef();

  const headerContext = useContext(HeaderContext);
  const settingsContext = useContext(SettingsContext);

  const { user } = settingsContext.state;
  
  const [organisationsFollowed, setOrganisationsFollowed] = useState([]);
  const [facilitiesFollowed, setFacilitiesFollowed] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refresh();
      setIsLoading(false);
    })();
    headerContext.setOptions({ titleImage: true, title: translate('general.home') });
  }, []);

  useEffect(() => {
    (async () => {
      if(user && user.organisationsFollowed) {
        let should = user.organisationsFollowed.map(tag => ({ term: { "id.keyword": tag } }))
        let organisationsQuery = { query: { bool: { should } } }
        if(user.organisationsFollowed.length == 0) {
          organisationsQuery.sort = [
            {
              "numFollowers": {
                order: "DESC"
              }
            }
          ]
        }
        organisationsQuery.size = 5;
        let organisations = await restApi.post('/organisations/search', organisationsQuery).then(r => r.Hits);
        setOrganisationsFollowed(organisations);
      }
      if(user && user.facilitiesFollowed) {
        let should = user.facilitiesFollowed.map(tag => ({ term: { "id.keyword": tag } }))
        let facilitiesQuery = { query: { bool: { should } } }
        if(user.facilitiesFollowed.length == 0) {
          facilitiesQuery.sort = [
            {
              "numFollowers": {
                order: "DESC"
              }
            }
          ]
        }
        facilitiesQuery.size = 5;
        let facilities = await restApi.post('/facilities/search', facilitiesQuery).then(r => r.Hits);
        setFacilitiesFollowed(facilities);
      }
    })();
  }, [user])

  const mapRecurringEvents = (events) => {
    let allEvents = events.filter(e => { return(!e.hasRecurringDate) });
    for(const e of events) {
      if(e.hasRecurringDate) {
        let date = getNextEventDate(e);
        allEvents.push({ ...e, date })
      }
    }
    return(allEvents.sort((a, b) => {
      return(a.date.start.intValue - b.date.start.intValue)
    }));
  }

  const refresh = async () => {
    const query = {
      query: {
        bool: {
          must: [
            {
              term: { visible: true },
              term: { featured: true }
            }
          ],
          filter: [
            {
              range: {
                "date.end.intValue": {
                  gt: Date.now().valueOf()
                }
              }
            },
            {
              range: {
                "publishDate.start.intValue": {
                  lte: Date.now().valueOf()
                }
              }
            }
          ]
        },
      },
      sort: [
        {
          "date.start.intValue": {
            order: "asc"
          }
        }
      ]
    }
    // regular first
    let allEvents = await restApi.post('/events/search', query).then(r => r.Hits);
    setFeaturedEvents(mapRecurringEvents(allEvents));
    // 
    const tagsQuery = {
      sort: [
        {
          "numFollowers": {
            order: "desc"
          }
        }
      ]
    }
    let tags = await restApi.post('/tags/search', tagsQuery).then(r => r.Hits);
    setPopularTags(tags);
  }

  const browseByTag = (tag) => {
    navigate(`/browse/${tag.id}`);
  }

  const viewEvent = (event) => {
    navigate(`/event/${event.id}`);
  }

  const viewOrganisation = (organisation) => {
    navigate(`/organisation/${organisation.id}`);
  }

  const viewFacility = (facility) => {
    navigate(`/facility/${facility.id}`);
  }

  const renderItem = (item, index) => {
    if (item.type === 'organisation') {
      return (
        <Pressable key={item.id} onPress={e => viewOrganisation(item)} >
          <View key={item.id} style={[theme.style.listItem, {display : 'flex', alignItems : 'center', justifyContent: 'flex-start'}]}>
            <EventImage event={item} width={32} height={32} type='organisation' />
            <View style={{ flex: 1, flexGrow: 1, paddingHorizontal: 15 }}>
              <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText]} data={item} id="title" />
            </View>
          </View>
        </Pressable>
      )
    } else if (item.type === 'facility') {
      return (
        <Pressable key={item.id} onPress={e => viewFacility(item)} >
          <View key={item.id} style={[theme.style.listItem, { display : 'flex', alignItems : 'center', justifyContent: 'flex-start' }]}>
            <EventImage event={item} width={32} height={32} type='facility' />
            <View style={{ flex: 1, flexGrow: 1, paddingHorizontal: 15 }}>
              <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText]} data=  {item} id="title" />
            </View>
          </View>
        </Pressable>
      )
    }
  }

  const renderUpcoming = () => {
    if (user &&
      user.tagsFollowed.length === 0 &&
      user.organisationsFollowed.length === 0 &&
      user.facilitiesFollowed.length === 0
    ) {
      return (
        <View style={[theme.style.contentBlock]}>
          <View style={[theme.style.content, theme.style.dropShadowIOS, { padding: 15 }]}>
            <TranslatedText style={[theme.style.defaultText]} id="home.flowinfo" />
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>
              {popularTags.map(tag => {
                return (
                  <Pressable key={tag.id} onPress={e => browseByTag({ id : tag.name })} style={[theme.style.tag, theme.style.dropShadowIOS, theme.style.tagSmall, { marginBottom: 7.5 }]}>
                    <TranslatedData style={[theme.style.tagSmallText]} data={tag} prefix="#" id="title" />
                  </Pressable>
                )
              })}
            </View>
            <TranslatedText id="home.flowexplore" />
          </View>
        </View>
      )
    } else {
      return(
        <UpcomingEvents viewEvent={viewEvent} user={user} ref={ref => { upcomingList.current = ref; }} />
      )
    }    
  }

  return (

    <ScrollView
      style={{ backgroundColor: theme.colors.contentBackgroundColor, flex: 1 }}     
    >

    {isLoading && (
      <View style={[theme.style.contentBlock]}>
        <ActivityIndicator size="large" color={theme.colors.text}/>
      </View>
    )}      

    {!isLoading && (
      <FeaturedEvents events={featuredEvents} onTagClicked={browseByTag} viewEvent={viewEvent} />
    )} 
      
    {renderUpcoming()}

    <View style={[theme.style.contentBlock]}>
      <View style={[theme.style.sectionHeader]}>
        {user && user.organisationsFollowed.length < 1 && (<TranslatedText style={[theme.style.defaultText, theme.style.defaultSectionHeaderText]} id="general.popular_organisations" />)}
        {user && user.organisationsFollowed.length > 0 && (<TranslatedText style={[theme.style.defaultText, theme.style.defaultSectionHeaderText]} id="general.followed_organisations" />)}
      </View>
      <View style={[theme.style.content, theme.style.dropShadowIOS]}>
        {organisationsFollowed.map(renderItem)}
      </View>
    </View>

    {facilitiesFollowed.length > 0 && (
      <View style={[theme.style.contentBlock]}>
        <View style={[theme.style.sectionHeader]}>
          {user && user.facilitiesFollowed.length < 1 && (<TranslatedText style={[theme.style.defaultText, theme.style.defaultSectionHeaderText]} id="general.popular_facilities" />)}
          {user && user.facilitiesFollowed.length > 0 && (<TranslatedText style={[theme.style.defaultText, theme.style.defaultSectionHeaderText]} id="general.followed_facilities" />)}
        </View>
        <View style={[theme.style.content, theme.style.dropShadowIOS]}>
          {facilitiesFollowed.map(renderItem)}
        </View>
      </View>
    )}
    
    </ScrollView>
  )
}

export { HomeScreen }