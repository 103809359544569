import React from 'react';

import { View,  Pressable } from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faMeta, faInstagram } from '@fortawesome/free-brands-svg-icons';

import * as Linking from 'expo-linking';

import { useTheme } from '../providers/ThemeContext';
import { TranslatedData, TranslatedText } from './TranslatedText';

const LinkPane = ({ item }) => {

  const theme = useTheme();

  const openLink = async (uri, appLink) => {
    if(!uri.match(/^http|https/))
      uri = `http://${uri}`;
    if(appLink) {
      let canOpen = await Linking.canOpenURL(appLink);
      if(canOpen) {
        try {
          await Linking.openURL(appLink);
          return;
        } catch(e) {
          console.error('Error opening url', e)
        }
      }
    }
    window.open(uri, '_blank')
    .catch((error) => {
      console.error(error)
      alert(`Kunde inte öppna länk ${urlObject.href}`)
    });    
  }

  const isEmpty = (str) => {
    return(str == undefined || str == null || str == '');
  }

  return(
    <>
      {(!isEmpty(item.webSite) || !isEmpty(item.facebookUrl) || !isEmpty(item.instagramUrl)) && (
        <View style={{ display : 'flex', justifyContent : 'center', alignItems : 'center', flexDirection : 'row' }}>
          {!isEmpty(item.webSite) && (
            <Pressable onPress={e => openLink(item.webSite)} style={[theme.style.button, theme.style.buttonSmall, theme.style.dropShadow, { width : '33%', margin : 2}]}>
              <View style={{ display : 'flex', alignItems : 'center', justifyContent : 'center', flexDirection : 'row'}}>
                <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 8 }} size={'lg'} color={theme.colors.white} />
                <TranslatedText style={[theme.style.buttonText]} id="general.web" />
              </View>
            </Pressable>
          )}
          {!isEmpty(item.facebookUrl) && (
            <Pressable onPress={e => openLink(item.facebookUrl, item.facebookAppUrl)} style={[theme.style.button, theme.style.buttonSmall, theme.style.dropShadow, { width : '33%', margin : 2}]}>
              <View style={{ display : 'flex', alignItems : 'center', justifyContent : 'center', flexDirection : 'row'}}>
                <FontAwesomeIcon icon={faMeta} style={{ marginRight: 8 }} size={'lg'} color={theme.colors.white} />
                <TranslatedText style={[theme.style.buttonText]} id="general.fb" />
              </View>
            </Pressable>
          )}
          {!isEmpty(item.instagramUrl) && (
            <Pressable onPress={e => openLink(item.instagramUrl, item.instagramAppUrl)} style={[theme.style.button, theme.style.buttonSmall, theme.style.dropShadow, { width : '33%', margin : 2}]}>
              <View style={{ display : 'flex', alignItems : 'center', justifyContent : 'center', flexDirection : 'row'}}>
                <FontAwesomeIcon icon={faInstagram} style={{ marginRight: 8 }} size={'lg'} color={theme.colors.white} />
                <TranslatedText style={[theme.style.buttonText]} id="general.ig" />
              </View>
            </Pressable>
          )}
        </View>
      )}
      {(item.links && item.links.length > 0) && (
        <View style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'flex-start', flexDirection : 'column' }}>
          <TranslatedText style={[theme.style.defaultIngressText, theme.style.marginFix]} id="general.links" />
          {item.links.map((link, i) => {
            return(
              <Pressable onPress={e => openLink(link.url)} key={i} style={{ display: 'flex', flexDirection: 'row', paddingVertical: 5 }}>
                <>
                  <Icon name='globe' type="font-awesome-5" style={{ marginRight: 8 }} size={theme.style.defaultIconSize} color={theme.colors.link} />
                  <TranslatedData style={[theme.style.defaultLink]} data={link} id="name" />
                </>
              </Pressable>
            )
          })}
        </View>
      )}
    </>
  )
}

export { LinkPane }