
import React, { useContext, useState, useEffect } from 'react';
import { View, Text, Pressable } from 'react-native';

import { TranslatedText } from '../components/TranslatedText';

import { SettingsContext } from '../providers/SettingsContext';
import { useTheme } from '../providers/ThemeContext';
import { useTranslation } from '../providers/translate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';

const AskAboutLocation = ({ children, onLocationChanged }) => {
  
  const theme = useTheme();

  const { translate } = useTranslation();
  const [showLocationButton, setShowLocationButton] = useState(false);
  const settingsContext = useContext(SettingsContext);
  const locationPermissions = (settingsContext.state.permissions || {}).location;

  useEffect(() => {
    setShowLocationButton(settingsContext.state.permissions && typeof(settingsContext.state.permissions.location) === 'undefined');
  }, [settingsContext.state])

  const cancelLocation = async () => {
    setTimeout(() => {
      settingsContext.dispatch({ type : 'setPermissions', value : { location : false }});
    }, 500)
  }

  const acceptLocation = () => {    
    setTimeout(() => {
      settingsContext.dispatch({ type : 'setPermissions', value : { location : true }})
    }, 500);
  }

  useEffect(() => {
    if(locationPermissions && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if(typeof(onLocationChanged) === 'function')
          onLocationChanged(position)
      });
    }
  }, [locationPermissions])

  return(
    <View>
      {showLocationButton && (
        <>
          <View style={{padding:20}}>
            <TranslatedText style={{ color: '#000' }} id="general.position_services_info" />
          </View>
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>          
            <Pressable style={[theme.style.button, {width: 200, marginRight: 20}]} onPress={acceptLocation}>
              <Text style={theme.style.buttonText}>{translate('general.allow_position_services')}</Text>
              <FontAwesomeIcon icon={faLocationArrow} size={'lg'} style={{ marginLeft: 10 }} color={'white'} />
            </Pressable>
            <Pressable type='clear' onPress={cancelLocation}>
              <Text>{translate('general.do_not_use_my_location')}</Text>
            </Pressable>
          </View>          
        </>
      )}
      {locationPermissions && children}
    </View>
  )
}

export { AskAboutLocation }