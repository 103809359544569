import { React } from 'react';

import { Text } from 'react-native';

import { useTranslation, useTranslatedData } from '../providers/translate';

const TranslatedText = ({ id, style }) => {  
  const { translate } = useTranslation();  
  return(
    <Text style={style}>
      {translate(id)}
    </Text>
  )
}

const TranslatedData = ({ prefix, data, id, fallback, style }) => {  
  const { translate } = useTranslatedData();  
  return(
    <Text style={style}>
      {prefix}{translate(data, id, fallback)}
    </Text>
  )
}

export { TranslatedText, TranslatedData }