import React, { useEffect, useState, useRef, useContext } from 'react';

import { View, ScrollView, Pressable, Text, ActivityIndicator } from 'react-native';

import { MapView } from '../components/MapView';
import { EventImage } from '../components/EventImage';
import { EventDate } from '../components/EventDate';
import { AskAboutLocation } from '../modals/AskAboutLocation';
import { useTheme } from '../providers/ThemeContext';
import { restApi } from '../providers/restApi';
import { FeaturedEvent } from '../components/FeaturedEvent';
import { FollowButton } from '../components/FollowButton';
import { OrganisationItem } from '../components/OrganisationItem';
import { TranslatedText, TranslatedData } from '../components/TranslatedText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faList, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import { getNextEventDate } from '../utils';

import { useTranslation, useTranslatedData } from '../providers/translate';

import { useParams, useNavigate } from 'react-router-dom';

import { HeaderContext } from '../components/Header';

const BrowseEventsScreen = ({ useMap: useMapByDefault }) => {

  const { tags: tags_, featured } = useParams();
  const navigate = useNavigate();

  const tags = tags_ ? tags_.split(',') : [];
  
  const { translate } = useTranslation();
  const { translate: translateData } = useTranslatedData();

  const theme = useTheme();  
  const [centerMapAt, setCenterMapAt] = useState({ latitude: 61.30716466554892, longitude: 17.06345804995182 });
  const [isLoading, setIsLoading] = useState();  
  const [events, setEvents] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [useMap, setUseMap] = useState(useMapByDefault || false);  
  const headerContext = useContext(HeaderContext);
  const [eventResult, setEventResult] = useState({ NumHits : '-'});
  const [tag, setTag] = useState({ loading: true, numFollowers: '-'});

  useEffect(() => {
    if(!useMap)
      refresh();
  }, []);

  const mapRecurringEvents = (events, doSort) => {
    let allEvents = events.filter(e => { return (!e.hasRecurringDate) });
    for (const e of events) {
      if (e.hasRecurringDate) {
        let date = getNextEventDate(e);
        allEvents.push({ ...e, date })
      }
    }
    return (!doSort ? allEvents : allEvents.sort((a, b) => {
      return (a.date.start.intValue - b.date.start.intValue)
    }));
  }
  
  const locationChanged = async (location) => {
    setCenterMapAt({
      latitude: location.coords.latitude,
      longitude: location.coords.longitude
    });
  }

  const onRegionChangeComplete = (location) => {
    refresh({
      latitude: location.latitude,
      longitude: location.longitude,
      latitudeDelta: location.latitudeDelta,
      longitudeDelta: location.longitudeDelta
    })
  }

  const goBack = () => {
    navigate(-1)
  }

  const browseByTag = (tag) => {
    navigate("/browse/" + tag.id)
  }

  const toggleMap = () => {    
    setUseMap(!useMap);
  }

  const navigateToEvent = (event) => {
    navigate("/event/" + event.id)
  }

  useEffect(() => {
    if(tags.length > 0) {
      let tagsQuery = { query : { bool : { must : [ { term : { "name.keyword" : tags[0] }}]}}}
      restApi.post('/tags/search', tagsQuery).then(r => r.Hits[0])
      .then(tag => {
        setTag({ numFollowers: 10 });
        headerContext.setOptions({
          title : `#${translateData(tag, 'title')}`,
          leftButtonIcon : <FontAwesomeIcon icon={faArrowLeft} color={theme.colors.text} size={'xl'} />,
          leftButtonAction : goBack
        });
      })
    }
  }, [])

  const refresh = async (mapRegion) => {
    setIsLoading(true);
    const query = {
      query: {
        bool: {
          must: [
            {
              term: { visible: true }
            }
          ],
          filter: [
            {
              range: {
                "date.end.intValue": {
                  gt: Date.now().valueOf()
                }
              }
            },
            {
              range: {
                "publishDate.start.intValue": {
                  lte: Date.now().valueOf()
                }
              }
            }
          ]
        },
      },
      sort: [
        {
          "date.end.intValue": {
            order: "asc"
          }
        }
      ]
    }
    if (tags.length) {
      let _tags = [{ bool: { should: tags.map(t => ({ term: { "tags.id.keyword": t } })) } }]
      query.query.bool.must = [...(query.query.bool.must || []), ..._tags]
    }

    let orgQuery = JSON.parse(JSON.stringify(query));
    delete orgQuery.sort;
    delete orgQuery.query.bool.filter;

    if (featured !== undefined) {
      if (featured)
        query.query.bool.must = [...(query.query.bool.must || []), { term: { featured } }]
      else
        query.query.bool.must_not = [...(query.query.bool.must_not || []), { term: { featured: true } }]
    }

    if (mapRegion) {
      const box = {
        geo_bounding_box: {
          location: {
            top_left: {
              lat: mapRegion.latitude + (mapRegion.latitudeDelta / 2),
              lon: mapRegion.longitude - (mapRegion.longitudeDelta / 2)
            },
            bottom_right: {
              lat: mapRegion.latitude - (mapRegion.latitudeDelta / 2),
              lon: mapRegion.longitude + (mapRegion.longitudeDelta / 2)
            }
          }
        }
      }
      query.query.bool.must = [...(query.query.bool.must || []), box]
    }
 
    try {
      let result = await restApi.post('/events/search', query);
      let organisationsResult = await restApi.post('/organisations/search', orgQuery);
      let events = mapRecurringEvents(result.Hits, true);
      let organisations = organisationsResult.Hits;
      setEvents(events);
      setOrganisations(organisations);
      setEventResult(result);
    } catch (error) {
      console.error('Could not load events', error);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  const followsChanged = (i) => {
    setTag(t => ({ ...t, numFollowers : t.numFollowers + i }))
  }

  const navigateToOrganisation = (organisation) => {
    navigate("/organisation/" + organisation.id);
  }
  
  return (
    <View style={{ backgroundColor: theme.colors.contentBackgroundColor, flex: 1 }}>
      <View style={[theme.style.contentBlock, { marginBottom: 0, alignItems: 'center' }]}>
        <Text style={theme.style.defaultText}>{eventResult.NumHits} <TranslatedText id="general.events" />, {tag.numFollowers || 0} <TranslatedText id="general.followers" /></Text>
        {!tag.loading && (
          <FollowButton 
            onChange={followsChanged} 
            followTitle={<TranslatedData prefix={`${translate('general.follow')} #`} data={tag} id="title" />} 
            unFollowTitle={<TranslatedData prefix={`${translate('general.unfollow')} #`} data={tag} id="title" />} 
            large={true} 
            type='tag'
            id={tag.name} 
          />
        )}
      </View>
      <View style={[theme.style.contentBlock, { marginBottom: 0 }]}>
        <View style={[theme.style.sectionHeader]}>
          <View style={{ flexDirection: 'row' }}>
            <Pressable onPress={toggleMap}
              style={[theme.style.tabButton, { borderBottomWidth: !useMap ? 1 : 0 }]}>
              <FontAwesomeIcon icon={faList}
                size={theme.style.defaultIconSize} color={theme.colors.text} />
              <TranslatedText style={[theme.style.defaultTextSmall]} id="general.show_as_list" />
            </Pressable>
            <Pressable onPress={toggleMap}
              style={[theme.style.tabButton, { borderBottomWidth: useMap ? 1 : 0 }]}>
              <FontAwesomeIcon icon={faMapMarkerAlt}
                size={theme.style.defaultIconSize} color={theme.colors.text} />
              <TranslatedText style={[theme.style.defaultTextSmall]} id="general.show_on_map" />
            </Pressable>
          </View>
        </View>
      </View>        
      {useMap && (
        <>
          <View style={[theme.style.contentBlock, theme.style.marginFix, { marginTop: 0 }]}>
            <View style={[theme.style.content, theme.style.dropShadow]}>                
              <MapView                
                centerAt={centerMapAt}
                onRegionChangeComplete={onRegionChangeComplete}
                events={events.filter(e => e.location)}
              >
              </MapView>
            </View>
          </View>
          <AskAboutLocation
            onLocationChanged={locationChanged}
          >
            <ScrollView style={{ flexGrow: 1 }}>
              <View style={[theme.style.contentBlock]}>
                <View style={[theme.style.content, theme.style.dropShadow]}>
                  {events.map((event) => {
                    return (
                      <Pressable key={event.id} onPress={e => navigateToEvent(event)} >
                        <View key={event.id} style={[theme.style.listItem]}>
                          <EventImage event={event} width={64} height={64} />
                          <View style={{ flex: 1, flexGrow: 1, paddingHorizontal: 15 }}>
                            <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText]} data={event} id='title' />
                            {event.tagLine != '' && (<TranslatedData style={[theme.style.defaultText]} data={event} id="tagLine" />)}
                          </View>
                          <View>
                            <EventDate fullDate={event.date}>{event.date.start.stringValue}</EventDate>
                          </View>
                        </View>
                      </Pressable>
                    );
                  })}
                  {!isLoading && events.length === 0 && (
                    <View style={[ theme.style.contentBlock]}>
                      <TranslatedText style={[theme.style.defaultText]} id="general.no_events_nearby" />
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
          </AskAboutLocation>
        </>
      )}      
      {!useMap && (
        <ScrollView style={{ flex: 1 }}>
          <View style={[{ marginTop: 0 }]}>
            <View style={{ flex: 1 }}>
              {events.map((event) => {
                return (
                  <FeaturedEvent onTagClicked={browseByTag} viewEvent={navigateToEvent} key={event.id} event={event} style={{ marginBottom: 15 }} />
                );
              })}
              {organisations.map((organisation) => {
                return (
                  <Pressable key={organisation.id} onPress={e => navigateToOrganisation(organisation)} >
                    <OrganisationItem organisation={organisation} />
                  </Pressable>
                );
              })}
              {events.length == 0 && organisations.length == 0 && !isLoading && (
                <View>
                  <TranslatedText style={[theme.style.defaultText]} id="general.oh_no_no_results"  />
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      )}
      {isLoading && 
        <ActivityIndicator size="large" color={theme.colors.text}/>
      }
    </View>
  )
}

export { BrowseEventsScreen }