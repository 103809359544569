
import App from "./src/App";
import css from "./src/assets/carousel.css";
import mainStyle from "./src/assets/main.css";
import 'mapbox-gl/dist/mapbox-gl.css';

export default function WebApp() {
  return (
    <App />
  );
}