import React, { useState, useEffect, useRef } from 'react';
import Map, { Marker, NavigationControl } from 'react-map-gl';

import { View, Pressable } from 'react-native';
import { EventImage } from './EventImage';

import { useTheme } from '../providers/ThemeContext';
import { useNavigate } from "react-router-dom";

const mapBoxToken = 'pk.eyJ1IjoidG9rZmFyYnJvcm4iLCJhIjoiY2t0YTJhcXVsMDZsejJ2bXlnM3ljbWNxMiJ9.HuQa3RQqf_orM5Gij3TVfQ';

const navControlStyle= {
  right: 10,
  top: 10
};

const MapBoxContainer = ({ centerAt, markers, events, onMove}) => {

  const mapRef = useRef(null);

  const theme = useTheme();
  const navigate = useNavigate();

  const [viewState, setViewState] = useState({
    latitude: centerAt.latitude,
    longitude: centerAt.longitude,
    zoom: 12
  })
  const [initialCenter, setInitialCenter] = useState(centerAt);
  
  useEffect(() => {
    if(mapRef.current) {
      mapRef.current.panTo([parseFloat(centerAt.longitude), parseFloat(centerAt.latitude)], { duration: 500 });
    }
  }, [centerAt, mapRef.current]);
  
  useEffect(() => {
    if(mapRef.current && typeof onMove === 'function') {
      let bounds = mapRef.current.getBounds();
      onMove({
        latitude: viewState.latitude,
        longitude: viewState.longitude,
        latitudeDelta: bounds._ne.lat - bounds._sw.lat,
        longitudeDelta: bounds._ne.lng - bounds._sw.lng
      });
    }
  }, [mapRef.current, initialCenter]);

  const onViewStateChange = (evt) => {
    setViewState(evt.viewState);
  }

  const onDragEnd = (evt) => {
    if(typeof onMove === 'function') {
      let bounds = mapRef.current.getBounds();
      onMove({
        latitude: evt.viewState.latitude,
        longitude: evt.viewState.longitude,
        latitudeDelta: bounds._ne.lat - bounds._sw.lat,
        longitudeDelta: bounds._ne.lng - bounds._sw.lng
      });
    }
  }

  return(
    <div
      style={{
        height: 400,
        marginBottom : 4,
        width: '100%',
        aspectRatio : 1
      }}
    >
      <Map
        ref={mapRef}
        {...viewState}
        mapboxAccessToken={mapBoxToken}
        width="100%"
        height="100%"
        onMove={onViewStateChange}
        onDragEnd={onDragEnd}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <NavigationControl style={navControlStyle} />
        {events?.map((event, i) => {
          return(
            <Marker
              key={i}
              longitude={event.location.lon}
              latitude={event.location.lat}
              offsetLeft={-24}
              offsetTop={-48}
            >
              <View style={[theme.style.mapMarker]}>
                <Pressable onPress={e => navigate(`/event/${event.id}`)}>
                  <EventImage event={event} width={30} height={30} style={{ borderRadius: 15, transform: [{ rotate: '135deg' }] }} />
                </Pressable>
              </View>
            </Marker>
          )
        })}
        {markers?.map((marker, i) => {
          return(
            <Marker
              key={i}
              longitude={marker.longitude}
              latitude={marker.latitude}
              offsetLeft={-24}
              offsetTop={-48}
            >
              <svg fill="blue" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
            </Marker>
          )
        })}
      </Map>
    </div>
  )
}

export { MapBoxContainer }