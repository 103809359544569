import React, { useContext, useState } from 'react';

import { View } from 'react-native';

import { HomeScreen } from '../screens/HomeScreen';
import { SearchScreen } from '../screens/SearchScreen';
import { SavedEventsScreen } from '../screens/SavedEventsScreen';
import { SettingsScreen } from '../screens/SettingsScreen';
import { LinksScreen } from '../screens/LinksScreen';

import { useTheme } from '../providers/ThemeContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faHeart, faSearch, faInfo, faCog} from '@fortawesome/free-solid-svg-icons'

import { SettingsContext } from '../providers/SettingsContext';

import {
  BrowserRouter,
  Link,
  Routes,
  Route,
  useMatch
} from "react-router-dom";

import { EventDetailsScreen } from '../screens/EventDetailsScreen';
import { OrganisationDetailsScreen } from '../screens/OrganisationDetailsScreen';
import { FacilityDetailsScreen } from '../screens/FacilityDetailsScreen';
import { Header, HeaderContext } from '../components/Header';
import { BrowseEventsScreen } from '../screens/BrowseEventsScreen';
import { LinkDetailsScreen } from '../screens/LinkDetailsScreen';

const icons = {
  'home': faHome,
  'heart': faHeart,
  'search': faSearch,
  'info': faInfo,
  'cog': faCog,
}

const Tab = ({ iconName, path, isTabletOrMobileDevice }) => {
  const theme = useTheme();
  const match = useMatch(path);
  const isFocused = match?.pathname
  const iconSize = isTabletOrMobileDevice ? '2x' : '2x';
  return(
    <Link
      to={path}
      style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, paddingBottom : 10 }}
    >
      <FontAwesomeIcon icon={icons[iconName]} size={iconSize} name={iconName} color={isFocused ? theme.colors.pink : theme.colors.text} />
    </Link>
  )
}


const BottomTabs = ({ isTabletOrMobileDevice }) => {

  const theme = useTheme();
  const settingsContext = useContext(SettingsContext);

  const [headerOptions, setOptions] = useState({
    titleImage: true
  });
  
  const defaultHeaderContext = {
    ...headerOptions,
    isTabletOrMobileDevice,
    setOptions
  }

  return(
    <BrowserRouter>
      <HeaderContext.Provider value={defaultHeaderContext}>
        <Header
          isTabletOrMobileDevice={isTabletOrMobileDevice} 
          {...headerOptions}
        />
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'justify-between', backgroundColor: '#fafafa', borderTopWidth: 1, borderTopStyle: 'solid', borderTopColor: '#ddd' }}>
          <Tab isTabletOrMobileDevice={isTabletOrMobileDevice} isFocused={true} iconName="home" path="/" />
          <Tab isTabletOrMobileDevice={isTabletOrMobileDevice} isFocused={false} iconName="search" path="/search" />
          <Tab isTabletOrMobileDevice={isTabletOrMobileDevice} isFocused={false} iconName="heart" path="/favorites" />
          <Tab isTabletOrMobileDevice={isTabletOrMobileDevice} isFocused={false} iconName="info" path="/links" />
          <Tab isTabletOrMobileDevice={isTabletOrMobileDevice} isFocused={false} iconName="cog" path="/settings" />
        </View>
        <View style={{ backgroundColor: theme.colors.safeAreaBackgroundColor, minHeight: 'calc(100vh - 200px)' }}>
          {settingsContext.state.language != "" && (
            <Routes>
              <Route path="/" title="hs" element={<HomeScreen />} />
              <Route path="/browse/:tags" element={<BrowseEventsScreen />} />
              <Route path="/search" element={<SearchScreen />} />
              <Route path="/favorites" element={<SavedEventsScreen />} />
              <Route path="/links" element={<LinksScreen />} />
              <Route path="/settings" element={<SettingsScreen />} />
              <Route path="/event/:id" element={<EventDetailsScreen />} />
              <Route path="/organisation/:id" element={<OrganisationDetailsScreen />} />
              <Route path="/facility/:id" element={<FacilityDetailsScreen />} />
              <Route path="/link/:id" element={<LinkDetailsScreen />} />
              </Routes>
          )}
        </View>
      </HeaderContext.Provider>
    </BrowserRouter>
  );
}

export { BottomTabs }