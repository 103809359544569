import React, { useContext, useState, useRef, useEffect, useImperativeHandle } from 'react';
import { View, Pressable, ActivityIndicator } from 'react-native';

import { EventImage } from './EventImage';
import { EventDate } from './EventDate';
import { MapView } from './MapView';
import { useTheme } from '../providers/ThemeContext';
import { AskAboutLocation } from '../modals/AskAboutLocation';

import { SettingsContext } from '../providers/SettingsContext';
import { restApi } from '../providers/restApi';
import { getNextEventDate } from '../utils';
import { buildUserEventsQuery, buildOrganisationEventsQuery, buildFacilityEventsQuery } from '../providers/query';
import { TranslatedData, TranslatedText } from './TranslatedText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

const UpcomingEvents = React.forwardRef((props, ref) => {

  const { useMap: useMapByDefault, viewEvent, user, organisationId, facilityId } = props;

  const centerMapAt = { latitude: 61.30716466554892, longitude: 17.06345804995182 };

  const [useMap, setUseMap] = useState(useMapByDefault);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const settingsContext = useContext(SettingsContext);

  const theme = useTheme();

  const locationPermissions = (settingsContext.state.permissions || {}).location;

  useImperativeHandle(ref, () => ({
    refresh: () => {
      refresh();
    }
  }));

  const mapRecurringEvents = (events) => {
    let allEvents = events.filter(e => { return (!e.hasRecurringDate) });
    for (const e of events) {
      if (e.hasRecurringDate) {
        let date = getNextEventDate(e);
        allEvents.push({ ...e, date })
      }
    }
    return (allEvents.sort((a, b) => {
      return (a.date.start.intValue - b.date.start.intValue)
    }));
  }

  useEffect(() => {
    refresh();
  }, [user])

  const refresh = async () => {
    setIsLoading(true);
    
    // find events based on user settings
    let eventsQuery = undefined;
    if(user) 
      eventsQuery = buildUserEventsQuery(user);
    else if(organisationId)
      eventsQuery = buildOrganisationEventsQuery(organisationId);
    else if(facilityId)
      eventsQuery = buildFacilityEventsQuery(facilityId);
    else
      return;

    // regular first
    let events = await restApi.post('/events/search', eventsQuery).then(r => r.Hits);
    setEvents(mapRecurringEvents(events));

    setIsLoading(false);
  }

  const toggleMap = () => {
    if (typeof (locationPermissions) === 'object') {
      settingsContext.dispatch({ type: 'setPermissions', value: { location: undefined } })
    }
    setUseMap(!useMap);
  }

  const navigateToEvent = (event) => {
    if (typeof (viewEvent) === 'function') viewEvent(event)
  }

  return (
    <>
      <View style={[theme.style.contentBlock]}>
        
        <View style={[theme.style.sectionHeader]}>
          <TranslatedText style={[theme.style.defaultSectionHeaderText]} id="events.upcoming" />
        </View>
        
        <View style={[theme.style.sectionHeader]}>
          <View style={{ flexDirection: 'row' }}>
            <Pressable onPress={toggleMap}
              style={[theme.style.tabButton, { borderBottomWidth: !useMap ? 1 : 0 }]}>
              <FontAwesomeIcon icon={faList} size={theme.style.defaultIconSize} color={theme.colors.text} />
              <TranslatedText style={[theme.style.defaultTextSmall]} id="general.show_as_list" />
            </Pressable>
            <Pressable onPress={toggleMap}
              style={[theme.style.tabButton, { borderBottomWidth: useMap ? 1 : 0 }]}>
              <FontAwesomeIcon icon={faMapMarkerAlt} size={theme.style.defaultIconSize} color={theme.colors.text} />
              <TranslatedText style={[theme.style.defaultTextSmall]} id="general.show_on_map" />
            </Pressable>
          </View>
        </View>

        {isLoading && (
          <View style={[theme.style.contentBlock]}>
            <ActivityIndicator size="large" color={theme.colors.text}/>
          </View>
        )}

        {!isLoading && events.length == 0 &&
          <View style={[theme.style.marginFix,{justifyContent:'center'}]}>
            <TranslatedText style={[theme.style.defaultText, {textAlign: 'center' }]} id="events.no_upcoming" />
          </View>
        }

        {!isLoading && events.length > 0 && useMap &&
            <View style={[theme.style.content, theme.style.dropShadowIOS]}>
            <MapView                
              centerAt={centerMapAt}
              events={events.filter(e => e.location)}
            >
            </MapView>
            <AskAboutLocation />
          </View>
        }

        {!isLoading && !useMap && (
          <View style={[theme.style.content, theme.style.dropShadowIOS]}>
            {events.map((event) => {
              return (
                <Pressable key={event.id} onPress={e => navigateToEvent(event)} >
                  <View key={event.id} style={[theme.style.listItem]}>
                    <EventImage event={event} width={65} height={65} />
                    <View style={{ flex: 1, flexGrow: 1, paddingHorizontal: 15 }}>
                      <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText]} data={event} id="title" />
                      {event.tagLine != '' && (<TranslatedData style={[theme.style.defaultText]} data={event} id="tagLine" />)}
                    </View>
                    <View>
                      <EventDate fullDate={event.date}>{event.date.start.stringValue}</EventDate>
                    </View>
                  </View>
                </Pressable>
              );
            })}
          </View>
        )}
        
      </View>
    </>
  )

});

export { UpcomingEvents }