import React from 'react';
import { Text, View } from 'react-native';
import { EventImage } from '../components/EventImage';

import { useTheme } from '../providers/ThemeContext';
import { useTranslation } from '../providers/translate';

import { TranslatedData, TranslatedText } from './TranslatedText';

const OrganisationItem = ({organisation}) => {

  const theme = useTheme();
  const { translate } = useTranslation();

  return(
    <View key={organisation.id} style={[theme.style.listItem]}>
      <EventImage event={organisation} width={64} height={64} type='organisation' />
      <View style={{ flex: 1, flexGrow: 1, paddingHorizontal: 15 }}>
        <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText]} data={organisation} id="title" / >
        <Text style={[theme.style.defaultTextSmall]}>
          {organisation.bucket && (`${organisation.bucket.doc_count} ${translate('general.events')} | `)}{organisation.numFollowers} <TranslatedText id="general.followers" />
        </Text>
      </View>
    </View>
  )
}

export { OrganisationItem }