import React, { useState, useEffect, useImperativeHandle, useContext } from 'react';

//import { SearchBar } from 'react-native-elements';
import { View, ActivityIndicator, StyleSheet, Text, Pressable, ScrollView, RefreshControl, Alert  } from 'react-native';

import { restApi } from '../providers/restApi';
import { useTheme } from '../providers/ThemeContext';
import { SettingsContext } from '../providers/SettingsContext';
import { useTranslation, useTranslatedData } from '../providers/translate';
import { TranslatedText, TranslatedData } from './TranslatedText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faClose, faExternalLinkAlt, faInfoCircle, faLink, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';

const DataObjectList = React.forwardRef((props, ref) => {

  const theme = useTheme();
  const navigate = useNavigate();

  const { translate } = useTranslation();
  const { translate: translateData } = useTranslatedData();
  const { filter, dataType, labelPlural, infoBox } = props;

  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [dbSearchResults, setDbSearchResults] = useState([]);
  const [tags, setTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const settingsContext = useContext(SettingsContext);

  const styles = StyleSheet.create({
    smallText: {
      fontSize: 11
    },
    subduedText: {
      color: theme.colors.subduedTextColor
    },
    listItemText: {
      color: theme.colors.listItemTextColor
    },
    searchIndicator: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10
    },
    searchResultsEmpty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20
    },
    searchResults: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    searchResultItem: {
      width: '100%',
      padding: 8,
      minHeight: 48,
      borderBottomColor: theme.colors.listItemBorderColor,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center'
    },
    centered: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4, width: 34, height: 34, marginRight: 8, backgroundColor: theme.colors.listItemBorderColor
    }
  })

  useImperativeHandle(ref, () => ({
    refresh: () => {
      refresh();
    }
  }));

  const tagsUri = `/${dataType}/tags`;
  useEffect(() => {
    (async () => {
      let tagAggregations = await restApi.get(tagsUri);
      setTags(tagAggregations.buckets);
      let allTags = await restApi.get('/tags').then(r => r.Hits).then(tags => tags.map(t => ({ value: t.name, label: t.title })));
      setAvailableTags(allTags);
    })();
  }, []);

  const searchUri = `/${dataType}/search`;

  useEffect(() => {
    let timeout = setTimeout(async () => {
      setIsSearching(true);
      await search();
      setIsSearching(false);
    }, 500);
    return (() => {
      clearTimeout(timeout);
    })
  }, [searchValue, filter]);

  const refresh = async () => {
    setIsRefreshing(true);
    await search()
    setIsRefreshing(false)
  }

  const search = async () => {
    const query = {
      "query": {
        "bool": {
          "should": [

          ],
          "must": [

          ]
        }
      },
      size: searchValue ? 100 : 10
    }
    if (filter && filter.tags && filter.tags.length) {
      let tags = [{ bool: { should: filter.tags.map(t => ({ term: { "tags.id.keyword": t } })) } }]
      query.query.bool.must = [...(query.query.bool.must || []), ...tags]
    }
    if (searchValue !== '') {
      if (searchValue[0] === '#') {
        const search = searchValue.substr(1, searchValue.length - 1);
        const matchedTags = tags.filter(t => (search.length === 0 || t.key.toLowerCase().indexOf(search.toLowerCase()) !== -1)).map(tag => ({ type: 'tag', value: availableTags.filter(at => at.label === tag.key)[0].value, label: tag.key, docs: tag.doc_count }));
        setDbSearchResults(matchedTags);
      } else {
        let timeout = setTimeout(
          (async () => {
            setIsSearching(true);
            const v = searchValue.toLowerCase();
            query.query.bool.should.push({
              wildcard: {
                title: {
                  value: `*${v}*`
                }
              }
            });
            query.query.bool.should.push({
              wildcard: {
                description: {
                  value: `*${v}*`
                }
              }
            });
            let linksResult = await restApi.post(searchUri, query);
            setDbSearchResults(linksResult.Hits);
            setIsSearching(false);
          }), 500);
        return (() => {
          clearTimeout(timeout);
        })
      }
    } else {
      (async () => {
        let linksResult = await restApi.post(searchUri, query);
        setDbSearchResults(linksResult.Hits)
      })();
    }
  }

  const searchTextChanged = (e) => {
    setSearchValue(e.target.value);
  }

  const browseByTag = (tag) => {
    navigate(`/browse/${tag.value}`);
  }

  const handleClick = (item) => {
    switch (item.type) {
      case 'link':
        return viewLink(item);
      case 'organisation':
        return viewOrganisation(item);
      case 'facility':
        return viewFacility(item);
    }
  }

  const viewOrganisation = async (organisation) => {
    navigate(`/organisation/${organisation.id}`);
  }

  const viewFacility = async (facility) => {
    navigate(`/facility/${facility.id}`);
  }

  const viewLink = async (link) => {
    const openIt = () => {
      let uri = link.webSite;
      window.open(uri, '_blank');      
    }
    if(link.openWithoutInfoDialog) {
      const permissions = settingsContext.state.permissions;
      if(permissions.canOpenLinks !== true) {
        Alert.alert('Öppna länkar', 'Länkar kommer att öppnas i extern webbläsare. Tryck OK för att godkänna.', [
          {
            text: 'Öppna inte',
            onPress: () => {

            },
            style: 'cancel',
          },
          {
            text: 'OK', 
            onPress: () => {
              settingsContext.dispatch({ type : 'setPermissions', value : { canOpenLinks : true }})
              openIt();
            }
          }
        ]);
      } else {
        openIt()
      }
    } else {
      navigate(`/link/${link.id}`);
    }
  }

  return (
    <>

      <View style={[{ backgroundColor: theme.colors.contentBackgroundColor, flex: 1 }]}>
          <View style={[theme.style.contentBlock]}>
            <div
              style={[{ overflow: 'visible', backgroundColor: 'transparent', borderWidth: 0, borderTopColor: 'transparent', borderBottomColor: 'transparent', padding: 0 }]}
            >
              <div
                style={{ backgroundColor: theme.colors.background, padding: 0, margin: 0, borderRadius: 20, display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent : 'space-between', paddingLeft : 20, paddingRight : 20 }}
              >
                <input
                  type="text"
                  placeholder={`${translate('general.search')} ${labelPlural}`}
                  style={{ fontSize: 14, padding: 10, width: '100%', border: 'none', outline: 'none', borderRadius: 20 }}  
                  onChange={searchTextChanged}
                  value={searchValue}
                  autocompletetype='off'
                />
                <button
                  style={{ backgroundColor: 'transparent', border: 'none', outline: 'none', padding: 0, margin: 0, cursor: 'pointer' }}
                  onClick={e => setSearchValue('')}
                >
                  <FontAwesomeIcon icon={faClose} size={'lg'} />
                </button>
              </div>
            </div>
          </View>

        <ScrollView
          refreshControl={
            <RefreshControl
              onRefresh={refresh}
              refreshing={isRefreshing}
            />
          }
        >

          <View style={[theme.style.contentBlock]}>

            {infoBox && !isSearching && !searchValue && (
              <View style={[theme.style.content, theme.style.dropShadowIOS, { padding: 20, marginBottom: 20 }]}>
                {infoBox}
              </View>
            )}
            
            {isSearching && (
              <View style={[styles.searchIndicator, { marginTop: 10 }]}>
                <ActivityIndicator size="large" color={theme.colors.text} />
              </View>
            )}

            {!isSearching && dbSearchResults && dbSearchResults.length > 0 && (
              <View style={[theme.style.content, theme.style.dropShadowIOS]}>
                {dbSearchResults.map((item) => {
                  if (item.type === 'tag') {
                    return (
                      <Pressable onPress={e => browseByTag(item)} key={index} style={[theme.style.listItem]}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Text style={[theme.style.defaultText, { flexGrow: 1 }]}>#{item.label}</Text>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={[theme.style.defaultText, { marginRight: 8 }]}>{item.docs} saker</Text>
                            <FontAwesomeIcon icon={faCaretRight} color={theme.colors.defaultText} style={[theme.style.defaultText]} size={'md'} />
                          </View>
                        </View>
                      </Pressable>
                    )
                  } else {
                    return (
                      <Pressable key={item.id} style={[theme.style.listItem]} onPress={e => handleClick(item)}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                          {item.type === 'organisation' && (
                            <FontAwesomeIcon icon={faMapMarkerAlt} color="#666"  style={{ marginRight: 8 }} size={'lg'} />
                          )}
                          {item.type === 'link' && (
                            <FontAwesomeIcon icon={faLink} color="#666"  style={{ marginRight: 8 }} size={'lg'} />
                          )}
                          <View style={{ flexGrow: 1 }}>
                            <TranslatedData style={[theme.style.defaultText, { flexGrow: 1 }]} data={item} id="title" fallback="name" />
                            {item.tags && item.tags.length > 0 && (
                              <Text style={[theme.style.defaultTextSmall]}>
                                {item.tags.map((tag) => {
                                  return (`#${translateData(tag, 'name')} `);
                                })}
                              </Text>
                            )}
                          </View>
                          {item.type == 'link' && (
                            <FontAwesomeIcon icon={faExternalLinkAlt} color="#666"  size={'lg'} />

                          )}
                          {item.type == 'organisation' && (
                            <FontAwesomeIcon icon={faInfoCircle} color="#666"  size={'lg'} />
                          )}
                        </View>
                      </Pressable>
                    )
                  }
                })}
              </View>
            )}
            {dbSearchResults && dbSearchResults.length == 0 && !isSearching && searchValue !== '' && (
              <View style={styles.searchResultsEmpty}>
                <TranslatedText style={theme.style.defaultText} id="general.oh_no_no_results" />
              </View>
            )}
            {dbSearchResults && !isSearching && searchValue === '' && (
              <View style={styles.searchResultsEmpty}>
                <TranslatedText style={theme.style.defaultText} id="general.use_search_to_find_links" />
              </View>
            )}
          </View>
        </ScrollView>
      </View>
    </>
  )
})

export { DataObjectList }