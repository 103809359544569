import React, { useContext } from 'react';

import { getColors } from './colors';
import { createStyle } from './style';

const createTheme = (scheme) => {
  let colors = getColors(scheme);
  return({
    isDark: false,
    style: createStyle(colors),
    Button: {
      backgroundColor:'red'
    },
    colors 
  })
}

const useTheme = () => {
  const theme = useContext(ThemeContext);
   return(theme);
}

const ThemeContext = React.createContext(createTheme('dark'));

export { ThemeContext, createTheme, useTheme }