
import axios from 'axios';
import { settings } from '../settings';

const restApi = axios.create({
  baseURL : settings.apiUrl
})

restApi.interceptors.request.use((config) => {
  //console.log('RestAPI', config.method, config.baseURL, config.url)
  return(config)
});

restApi.interceptors.response.use((response) => {
  return(response.data)
}, (error) => {
  console.log('API Error', error, error.response.data.errorMessage);
  if(error && error.response && error.response.data && error.response.data.error) {
    return Promise.reject(error.response.data.error)
  }
  if(error && error.response && error.response.data && error.response.data.errorMessage) {
    return Promise.reject(error.response.data.errorMessage)
  }
  return(Promise.reject(error.message || 'API Error'));
})

const getScaledImageUrl = (image, width, height, emptyImage) => {
  if(image.external) {
    return({ uri: image.downloadUrl })
  }
  if(image.id) {
    return({ uri : `${settings.apiUrl}/image/${image.id}/${width}/${height}` })
  } else if(image.downloadUrl) {
    if(image.downloadUrl.indexOf('media/') === -1 && image.downloadUrl.indexOf('image/') === -1) 
      return(emptyImage);
    return({ uri : `${image.downloadUrl.replace('media/', 'image/')}/${width}/${height}` })
  }
  return(emptyImage);
}

export { restApi, getScaledImageUrl }