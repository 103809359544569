import React, { useContext } from 'react';

import { Pressable } from 'react-native';

import { SettingsContext } from '../providers/SettingsContext';
import { useTheme } from '../providers/ThemeContext';
import { TranslatedData } from '../components/TranslatedText';

const Tag = ({ id, onPress }) => {

  const settingsContext = useContext(SettingsContext);
  const theme = useTheme();

  const tag = settingsContext.state?.tags?.find(tag => tag.name === id) || { id, name: id, title: id };
  
  return(
    <Pressable onPress={onPress} style={[theme.style.tag, theme.style.tagSmall]} >
      <TranslatedData style={[theme.style.tagSmallText]} prefix="#" data={tag} id="title" />
    </Pressable>
  );

}

export { Tag }