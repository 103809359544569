import React, { useState, useRef, useEffect } from "react";
import { View } from "react-native"

import { MapBoxContainer } from "./MapBoxContainer";

const MapView = ({ centerAt, markers, events, onRegionChangeComplete }) => {

  const handleLocationChange = (region) => {
    if(typeof onRegionChangeComplete === 'function') {
      onRegionChangeComplete(region);
    }
  }

  return(
    <View>
      <MapBoxContainer
        events={events}
        markers={markers}
        onMove={handleLocationChange}
        centerAt={centerAt}
      />
    </View>
  );

};

export { MapView }