
import { StyleSheet } from 'react-native';

const defaultHtmlStyle = { p : { margin : 0, padding : 0 }, defaultIconSize: 18 }

const createStyle = (colors) => {
  const style = StyleSheet.create({
    /* Default text styles*/
    defaultText: {
      fontSize: 14,
      color: colors.text,
    },
    upperFirst: {
      textTransform: 'capitalize'
    },
    defaultTextSmall: {
      fontSize: 12,
      color: colors.text,
    },
    defaultSectionHeaderText: {
      fontSize: 14,
      fontWeight: '700',
      textTransform: 'uppercase',
      color: colors.sectionHeader,
    },
    defaultHeaderText: {
      fontSize: 15,
      color: colors.headerTitleColor,
      fontWeight: '700'
    },
    defaultTitleText: {
      fontSize: 26,
      color: colors.headerTitleColor,
      fontFamily: 'ExtraBold'
    },
    defaultIngressText: {
      fontSize: 14,
      fontWeight: '700',
      color: colors.text,
    },
    defaultLink: {
      fontSize: 14,
      color: colors.link,
    },
    /* dividers and headers */
    sectionHeader: {
      flexDirection: 'row',
      marginBottom: 15,
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      borderBottomWidth: 0,
      borderBottomColor: colors.sectionHeader
    },
    tabButton: {
      paddingHorizontal: 5, paddingVertical: 7, flexGrow: 1, alignItems: 'center',
    },
    /* main/screen header style */

    screenHeader: {
      paddingHorizontal: 15,
      height: 90,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 20,
      backgroundColor: colors.safeAreaBackgroundColor
    },
    screenHeaderTitleContainer: {
      paddingHorizontal: 15,
      paddingVertical: 5,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'flex-end'
    },
    screenHeaderTitleImage: {
      marginRight: 5,
      width: 200, height: 38,
    },
    screenHeaderText: {
      fontSize: 20,
      fontWeight: '700',
      color: colors.text,
    },
    screenHeaderLeftContainer: {
      paddingVertical: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      minWidth: 50
    },
    screenHeaderLeftButtonTitle: {
      fontSize: 14,
      color: colors.headerTitleColor,
    },
    screenHeaderRightContainer: {

      paddingVertical: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      minWidth: 50
    },
    screenHeaderRightButtonTitle: {
      fontSize: 14,
      color: colors.headerTitleColor,
    },
    /* elevation/shadowstuff */
    dropShadow: {
      boxShadowColor: "#000",
      boxShadowOffset: {
        width: 0.5,
        height: 1,
      },
      boxShadowOpacity: 0.32,
      boxShadowRadius: 1.6,
      elevation: 2,
    },
    dropShadowIOS: {
      /*
      shadowColor: "#000",
      shadowOffset: {
        width: 0.5,
        height: 1,
      },
      shadowOpacity: 0.32,
      shadowRadius: 1.6,
      */
     boxShadow: '8px 8px 10px -7px rgba(0,0,0,0.32)'
    },
    dropShadowAndroid: {
      elevation: 2,
    },
    dropShadowSmall: {
      boxShadowColor: "#000",
      boxShadowOffset: {
        width: 0,
        height: 0.5,
      },
      boxShadowOpacity: 0.18,
      boxShadowRadius: 1.00,
      elevation: 1,
    },
    textShadow: {
      /*
      textShadowColor: 'rgba(0,0,0,0.6)',
      textShadowOffset: { width: 1, height: 1 },
      textShadowRadius: 3*/
    },
    /* tag stylling */
    tag: {
      marginRight: 10,
      marginBottom: 10,
      backgroundColor: colors.pink,
      borderRadius: 6,
      paddingHorizontal: 10,
      paddingVertical: 7,
      borderWidth: 1,
      borderColor: colors.pink,
      flexDirection: 'row',
      overflow: 'hidden',
      justifyContent: 'space-between'
    },
    follow: {
      marginRight: 10,
      marginBottom: 10,
      backgroundColor: colors.orange,
      borderRadius: 6,
      paddingHorizontal: 10,
      paddingVertical: 7,
      borderWidth: 1,
      borderColor: colors.orange,
      flexDirection: 'row',
      overflow: 'hidden',
      justifyContent: 'space-between'
    },
    inactiveTag: {
      backgroundColor: 'transparent',
      borderColor: colors.grey
    },
    tagSmall: {
      paddingVertical: 3,
      paddingHorizontal: 8,
      borderRadius: 6,
    },
    tagText: {
      textTransform: 'uppercase',
      color: colors.invertedText,
      fontSize: 14,
    },
    tagSmallText: {
      textTransform: 'uppercase',
      color: colors.invertedText,
      fontSize: 12,
    },
    inactiveTagText: {
      color: colors.text,
    },
    /* event listings */
    eventCard: {
      position: 'relative',
      paddingLeft: 15,
      paddingRight: 15,
      width: '100%',
      overflow: 'hidden',
    },
    linearGradient: {
      backgroundColor: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%)',
    },
    eventCardTitle:
    {
      fontSize: 20,
      color: colors.invertedText
    },
    eventCardTagline:
    {
      fontSize: 14,
      color: colors.invertedText
    },
    eventDate: {
      backgroundColor: colors.orange,
      paddingHorizontal: 8,
      paddingVertical: 8,
      borderRadius: 7,
      alignItems: 'center'
    },
    eventDateText:
    {

    },
    eventDistance: {
      backgroundColor: colors.turquoise,
      fontSize: 12,
      paddingVertical: 5,
      paddingHorizontal: 5,
      borderRadius: 5,
      color: colors.invertedText,
    },
    listItem: {
      backgroundColor: colors.background,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: 15,
      flex: 1,
      borderColor: colors.border,
      borderTopWidth: 1
    },
    listItemNarrow : {
      paddingTop: 5,
      paddingBottom: 5,
    },
    /* contentgrid and modals */
    content: {
      backgroundColor: colors.background,
      borderRadius: 20,
      overflow: 'hidden'
    },
    contentBlock: {
      paddingHorizontal: 15,
      marginVertical: 7.5
    },
    marginFix: {
      marginVertical: 7.5
    },
    modal: {
      flex: 1,
      backgroundColor: colors.background,
      borderRadius: 20,
      overflow: 'hidden'
    },
    modalHeader: {
      paddingHorizontal: 15,
      paddingVertical: 15,
    },
    modalBody: {
      paddingHorizontal: 15,
      paddingVertical: 15,
    },
    modalFooter: {
      paddingHorizontal: 15,
      paddingVertical: 15,
    },
    /* buttons and actions */
    button: {
      backgroundColor: colors.action,
      padding: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 7,
      color: colors.text,
      marginVertical: 7.5
    },
    buttonSmall: {
      padding: 7,
      paddingHorizontal: 10,
      borderRadius: 6,
      marginVertical: 7.5
    },
    buttonText: {
      fontSize: 14,
      color: colors.invertedText
    },
    buttonHollow: {
      backgroundColor: colors.grey30,
    },
    buttonHollowText: {
      color: colors.text
    },
    mapMarker: {
      borderRadius: 20, borderTopLeftRadius: 0, borderWidth: 5, backgroundColor: colors.primary, borderColor: colors.primary
    }
  })
  return (style);
}

export { createStyle, defaultHtmlStyle }