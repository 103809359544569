import React, { useEffect, useContext } from 'react';

import { SavedEvents } from '../components/SavedEvents';

import { useTranslation } from '../providers/translate';
import { HeaderContext } from '../components/Header';

const SavedEventsScreen = () => {

  const { translate } = useTranslation();
  const headerContext = useContext(HeaderContext);

  useEffect(() => {
    headerContext.setOptions({ title: translate('general.saved_events') });
  }, []);
  
  return(
    <SavedEvents />
  )
  
}

export { SavedEventsScreen }