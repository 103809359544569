export const en = {
  general: {
    home: 'SHMN.Nu',
    get_app: 'Get the app in App Store or Google Play',
    save: 'Save',
    ok: 'Ok',
    cancel: 'Cancel',
    tom: 'to',
    loading: "Loading...",
    settings: 'Settings',
    search: 'Search',
    saved_events: 'Saved events',
    popular_organisations: "Popular organisations",
    followed_organisations: "Organisations you follow",
    popular_facilities: "Popular facilities",
    followed_facilities: "Facilities you follow",
    followed_tags: 'Tags you follow',
    language: 'Language',
    allow_push_notifications: 'Allow push notifications',
    allow_position_services: 'Allow position services',
    activate_position_services_info: 'Enable position service in your Settings to use features like showing events in your area',
    location_services_disabled: 'You have chosen not to use position services but you can always change your mind. If you enable position services you can see what is happening in your area.',
    followers: 'followers',
    events: 'events',
    facilities: 'facilities',
    organisations: 'organisations',
    facility: 'facility',
    organisation: 'organisation',
    tag: 'tag',
    tags: 'tags',
    show_more_events: 'Show more events',
    show_more_facilities: 'Show more facilities',
    show_more_organisations: 'Show more organisations',
    show_more_tags: 'Show more tags',
    oh_no_no_results: 'Oh no, no results found',
    use_search_to_find: 'Use the search box to find more things',
    use_search_to_find_links: 'Use the search box to find more links',
    show_as_list: 'Show as list',
    show_on_map: 'Show on map',
    no_saved_events: 'You have not saved any events yet',
    links: 'Links',
    link: 'Link',
    links_view_info: 'Here you will find links to good things like the school menu, Be Smart and more.',
    related_links: 'Related links',
    open_link: 'Open link',
    follow: 'Follow',
    unfollow: 'Stop follow',
    starts_at: 'Starts at',
    ends_at: 'Ends at',
    no_events_nearby: 'Did not find any events nearby',
    keep_updated: 'Keep updated',
    keep_updated_info: 'We would like to keep you updated with the latest news and would therefore like to send notifications to you when something is going on.',
    dont_want_notifications: 'I do not want notifications',
    position_services_info: 'Do you want to see events in your area? If so, we need to know your position. We do not store this information and we do not share it with anyone.',
    do_not_use_my_location: 'No thanks, do not use my location',
    show_settings: 'Show settings',
    web: 'Web',
    fb: 'Facebook',
    ig: 'Instagram',
    follows: 'Follows',
    follow: 'Follow',
    search_placeholder: 'Search for events, facilities, organisations and tags',
    choose_language: 'Choose language',
  },
  events: {
    upcoming: 'Upcoming events',
    no_upcoming: 'No upcoming events.'
  },
  home: {
    flowinfo: "Your flow adapts to the tags you choose to follow. Right now you are not following any tags so here are some suggestions for the most popular tags.",
    flowexplore: "You can explore on your own on the tab with a magnifying glass in the menu at the bottom of the app. There you will find all tags, events, activities and facilities."
  },
  error: {
    could_not_open_link: "Could not open link",
    could_not_load_events: "Could not load events",
  },
  news: {
    whats_new: 'What is new in this version',
    app_is_updated: "Your app has been updated",
    latest: "New in this version is that you can choose language. Go to settings and choose language. You can choose between Swedish and English."
  }
}