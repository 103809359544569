import React, { useEffect, useState, useContext} from 'react';
import { SafeAreaView, View, ActivityIndicator, Pressable, ScrollView } from 'react-native';

import { restApi } from '../providers/restApi';
import { useTheme } from '../providers/ThemeContext';

import { TranslatedText, TranslatedData } from '../components/TranslatedText';

import { useTranslatedData } from '../providers/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';

import { HeaderContext } from '../components/Header';

const LinkDetailsScreen = () => {

  const { translate } = useTranslatedData();
  const { id: linkId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState({ description: '', webSite: '' });
  const theme = useTheme();
  const headerContext = useContext(HeaderContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    headerContext.setOptions({ title: translate('general.link') });    
  }, []);

  useEffect(() => {
    (async () => {
      let link = await restApi.get(`/links/${linkId}`);
      setLink(link);
      setIsLoading(false);
    })();
  }, [linkId])

  const goBack = () => {
    navigate('..');
  }

  const openLink = () => {
    let uri = link.webSite;
    if(!uri.match(/^http|https/))
      uri = `http://${uri}`;
    window.open(uri, '_blank');
  }

  return (
    <SafeAreaView style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.3)' }}>
      <View style={{ padding: 20, width: '80%', aspectRatio: 0.7, borderRadius: 15, backgroundColor: theme.colors.contentBackgroundColor, shadowColor: "#000", shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5 }}>
        {!isLoading && (
          <View style={{position : 'relative', flex : 1, padding : 5}}>
            <ScrollView style={{ flex: 1, padding: 0 }}>
              <View style={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <TranslatedData style={[theme.style.defaultHeaderText]} data={link} id="title" />
              </View>
              <div dangerouslySetInnerHTML={{ __html: translate(link, 'description') }} 
              >
              </div>
            </ScrollView>
            <Pressable onPress={openLink} style={[theme.style.button,theme.style.dropShadow]}>
              <>
              <FontAwesomeIcon icon={faExternalLinkAlt} size={'lg'} color={theme.colors.invertedText} name='external-link-alt' style={{ marginRight: 8 }} />
              <TranslatedText style={[theme.style.buttonText]} id="general.open_link" />
              </>
            </Pressable>
            <Pressable style={{ position: 'absolute', right: -12, top: -12 }} onPress={goBack}>
              <FontAwesomeIcon icon={faClose} size={'lg'} />
            </Pressable>
          </View>
        )}
        {isLoading && (
          <View style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color={theme.colors.text}/>
          </View>
        )}
      </View>
    </SafeAreaView>
  )
}

export { LinkDetailsScreen }