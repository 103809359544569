import React, { useContext, useEffect } from 'react';

import { View, Image, Pressable, Text } from 'react-native';

import { useTheme } from '../providers/ThemeContext';

const HeaderContext = React.createContext({});

const Header = ({ isTabletOrMobileDevice }) => {

  const theme = useTheme();
  const headerContext = useContext(HeaderContext);
  const { visible, title, leftButtonAction, rightButtonAction, rightButtonIcon, rightButtonTitle, leftButtonTitle, leftButtonIcon, titleImage, followButton } = headerContext;

  useEffect(() => {
    document.title = title;
  }, [title]);
  
  if(visible === false) return null;

  const width = isTabletOrMobileDevice ? 70 : 200;
  const height = isTabletOrMobileDevice ? 60 : 80;
  const paddingBottom = isTabletOrMobileDevice ? 0 : 0;

  return (
    <View style={[{...theme.style.screenHeader, height, paddingBottom}]}>
      <Pressable onPress={leftButtonAction} style={[theme.style.screenHeaderLeftContainer]}>
        {leftButtonIcon}
        <Text style={[theme.style.defaultText]}>{leftButtonTitle}</Text>
      </Pressable>
      <View style={[theme.style.screenHeaderTitleContainer]}>
        {titleImage &&
          <Image source={require('../assets/shmn.nu.png')} resizeMode={'contain'} style={[{ ...theme.style.screenHeaderTitleImage, width } ]} />
        }
        {!titleImage && title &&
          <Text style={theme.style.screenHeaderText}>{title || 'header'}</Text>
        }
        {followButton}
      </View>
      <Pressable onPress={rightButtonAction} style={[theme.style.screenHeaderRightContainer]}>
        <Text style={[theme.style.defaultText]}>{rightButtonTitle}</Text>
        {rightButtonIcon}
      </Pressable>
    </View>
  )
}

export { Header, HeaderContext }