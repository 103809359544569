import React from 'react';

import { storage } from '../providers/storage';

const defaultSettingsState = { 
  setExpoToken: undefined,
  user: undefined,
  language: '',
  whatsNewClosed: true,
  tags: [],
}

const SettingsContext = React.createContext(defaultSettingsState);

const settingsReducer = (state, action) => {
  switch(action.type) {
    case 'setTags':
      return({ ...state, tags: action.value })
    case 'setWhatsNewClosed':
      storage.setWhatsNewClosed(action.value);
      return({ ...state, whatsNewClosed: action.value })
    case 'setLanguage':
      storage.setLanguage(action.value);
      return({ ...state, language: action.value })
    case 'setPermissions':
      const permissions = { ...state.permissions, ...action.value };
      storage.setPermissions(permissions);
      return({ ...state, permissions })
    case 'setLocationServicesDisabled':
      return({ ...state, locationServicesDisabled : action.value })
    case 'setStoredValues':
      return({ ...state, user : { ...state.user, ...action.value.user }, permissions : { ...state.permissions, ...action.value.permissions }, language: action.value.language, whatsNewClosed: action.value.whatsNewClosed })
    case 'tagsFollowed':
      return({ ...state, user : { ...state.user, tagsFollowed : action.value } })
    case 'eventsFollowed':
      return({ ...state, user : { ...state.user, eventsFollowed : action.value } })
    case 'organisationsFollowed':
      return({ ...state, user : { ...state.user, organisationsFollowed : action.value } })
    case 'facilitiesFollowed':
      return({ ...state, user : { ...state.user, facilitiesFollowed : action.value } })
    default:
      console.error('Invalid action for settingsReducer', action.type)
      return(state)
  }
}

const settingsInitializer = async (data) => {
  let permissions = await storage.getPermissions();
  let user = await storage.getUser();
  let language = await storage.getLanguage();
  const _whatsNewClosed = await storage.getWhatsNewClosed();
  let whatsNewClosed = _whatsNewClosed === "true" ? true : _whatsNewClosed === "false" ? false : null;
  const initialState = { ...data, permissions, user, language, whatsNewClosed }
  return(initialState)
}

export { SettingsContext, defaultSettingsState, settingsReducer, settingsInitializer}