import React, { useEffect } from 'react';

import { Pressable, Text } from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import { useTheme } from '../providers/ThemeContext';
import { restApi } from '../providers/restApi';
import { useTranslatedData } from '../providers/translate';

const FacilityLineItem = ({id, name, onPress}) => {

  const theme = useTheme();
  const [facility, setFacility] = React.useState({ id, name });
  const {translate} = useTranslatedData();

  useEffect(() => {
    (async () => {
      if (id) {
        const response = await restApi.get(`/facilities/${id}`);
        if (response.id) {
          const name = translate(response, 'title', 'name');
          setFacility(o => ({...o, name}));
        }
      }
    })();
  }, [id]);

  return (
    <Pressable onPress={onPress} style={{ display: 'flex', flexDirection: 'row', paddingVertical: 5 }}>
      <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 8 }} size={theme.style.defaultIconSize} color={theme.colors.link} />
      <Text style={[theme.style.defaultLink]}>{facility.name}</Text>
    </Pressable>
  )
}

export { FacilityLineItem }