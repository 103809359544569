import React, { useContext } from 'react';

import { Text, View } from 'react-native';
import { useTheme } from '../providers/ThemeContext';

import { SettingsContext } from '../providers/SettingsContext';
import { TranslatedText } from './TranslatedText';

import moment from 'moment';
import 'moment/locale/sv';

const dateStrings = {
  'sv': {
    lastDay: '[Igår]',
    sameDay: '[Idag]',
    nextDay: '[Imorgon]',
    nextWeek: 'D',
    sameElse: 'D'
  },
  'en': {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'D',
    sameElse: 'D'
  }
}

const EventDate = ({ style, fullDate, children }) => {

  const { state } = useContext(SettingsContext);
  const { language } = state;

  const theme = useTheme();

  let l = language || 'sv';
  moment.updateLocale(l, {
    calendar: dateStrings[l]
  });

  let fontSize = 14;
  
  let stringDate = moment(children).calendar();
  let stringEndDate = moment(fullDate?.end?.stringValue).calendar();
  let showMonth = parseInt(stringDate) ? true : false;
  let showEndMonth = parseInt(stringEndDate) ? true : false;
  let showEndDate = false;

  if(fullDate?.end?.stringValue) {
    let startedAt = moment(fullDate.start.stringValue).valueOf()
    let endsAt = moment(fullDate.end.stringValue).valueOf();
    let now = moment(new Date()).valueOf();
    if(startedAt < now && endsAt > now) {
      stringDate = moment(new Date()).calendar();
      showMonth = false;
      showEndDate = !fullDate.finalEnd;
    }
  }

  if(showMonth)
    fontSize = 18;
  
  if(showEndMonth)
    fontSize = 12;

  return (
    <View style={[theme.style.eventDate, theme.style.dropShadow, style]}>
      <Text style={[theme.style.defaultHeaderText, { color: theme.colors.invertedText, lineHeight: 20, fontSize }]}>
        {stringDate}
      </Text>
      {showMonth &&
        <Text style={[theme.style.defaultText, { color: theme.colors.invertedText, lineHeight: fontSize, fontSize }]}>
          {moment(children).format('MMM')}
        </Text>
      }
      {showEndDate && (
        <>
          <TranslatedText style={[theme.style.defaultText, { color: theme.colors.invertedText, lineHeight: fontSize, fontSize }]} id="general.tom" />
          <Text style={[theme.style.defaultHeaderText, { color: theme.colors.invertedText, lineHeight: 20, fontSize }]}>
            {stringEndDate}
          </Text>
          {showEndMonth && (
            <Text style={[theme.style.defaultText, { color: theme.colors.invertedText, lineHeight: fontSize }]}>
              {moment(children).format('MMM')}
            </Text>
          )}
        </>
      )}
    </View>
  )

}

export { EventDate }