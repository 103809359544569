import React from 'react';
import { Dimensions, View, StyleSheet } from 'react-native';
import { FeaturedEvent } from './FeaturedEvent';
import {Carousel} from 'react-responsive-carousel';

const styles = StyleSheet.create({
  viewPager: {
    flex: 1
  },
  page: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const FeaturedEvents = ({ events, onTagClicked, viewEvent }) => {

  const [eventIndex, setEventIndex] = React.useState(0);

  const pageSelected = (e, ee) => {
    setEventIndex(e.nativeEvent.position);
  }

  const renderThumbs = () => {

  }
  return(
    <View>
      <View style={{ flex: 1 }}>
        <Carousel renderThumbs={renderThumbs} showArrows={true} showIndicators={true} showStatus={false} onPageSelected={pageSelected} initialPage={0}>
          {[...events, ...events, ...events].map((event, index) => {
            return (
              <View key={index} style={styles.page}>
                <FeaturedEvent key={index} event={event} onTagClicked={onTagClicked} viewEvent={viewEvent}/>
              </View>
            )
          })}
        </Carousel>
      </View>
    </View>
  )
}

export { FeaturedEvents }