
  /**
   * 
   * @param {*} eventStart 
   * @param {*} eventEnd 
   * @param {*} pattern 
   * @returns 
   */
  const getNextDate = (eventStart, eventEnd, pattern) => {
    let endDate = new Date(eventEnd);
    let nextEndDate = new Date(eventStart);
    nextEndDate.setHours(endDate.getHours())
    nextEndDate.setMinutes(endDate.getMinutes())
    let now = new Date().valueOf();
    let c = 0;
    while(true && c++ < 1000) {
      if(pattern.type === 'weekly' || pattern.type === 'bi-weekly') {
        let _t = new Date(nextEndDate);
        for(const d of pattern.days.sort((a, b) => a - b)) {
          const _d = _t.getDay();
          _t.setDate(_t.getDate() + (d - _d))
          if(_t.valueOf() > now)
            return(_t.valueOf())
        }
        nextEndDate.setDate(nextEndDate.getDate() + (pattern.type === 'bi-weekly' ? 14 : 7));
      } else if(pattern.type === 'monthly' || pattern.type === 'bi-monthly') {
        let _t = new Date(nextEndDate);
        _t.setDate(pattern.dayOfMonth)
        if(_t.valueOf() > now)
          return(_t.valueOf())
        nextEndDate.setMonth(nextEndDate.getMonth() + (pattern.type === 'bi-monthly' ? 2 : 1));
      }  else if(pattern.type === 'yearly') {
        let _t = new Date(nextEndDate);
        _t.setDate(pattern.dayOfMonth)
        _t.setMonth(pattern.monthOfYear)
        if(_t.valueOf() > now)
          return(_t.valueOf())
        nextEndDate.setFullYear(nextEndDate.getFullYear() + 1);
      }
    }
    return(nextEndDate.valueOf());
  }

  /**
   * 
   */
   const getNextEventDate = (e) => {
    let firstStart = new Date(e.date.start.intValue);
    let finalEnd = new Date(e.date.end.intValue);
    let nextDateEnd = getNextDate(e.date.start.intValue, e.date.end.intValue, e.recurringPattern);
    let nextDateStart = new Date(nextDateEnd);
    nextDateStart.setHours(firstStart.getHours())
    nextDateStart.setMinutes(firstStart.getMinutes())
    const nextStartDate = { intValue : nextDateStart.valueOf(), stringValue : new Date(nextDateStart).toISOString() };
    const nextEndDate = { intValue : nextDateEnd.valueOf(), stringValue : new Date(nextDateEnd).toISOString() };
    return({ start : nextStartDate, end : nextEndDate, finalEnd })
  }
 

  const routeHashes = {
    'HomeStack': '#home',
    'SearchStack': '#search',
    'SavedEventsStack': '#saved',
    'LinksStack': '#links',
    'SettingsStack': '#settings',
  }

  const getInitialRouteName = () => {
    let name = 'HomeScreen';
    let tabName = 'HomeStack';
    let params = { }
    switch (document.location.hash.split('/')[0]) {
      case '#home':
        tabName = 'HomeStack';
        break;
      case '#event':
        tabName = 'HomeStack';
        name = 'EventDetails';
        params = { eventId : document.location.hash.split('/')[1] };
        break;
      case '#search':
        tabName = 'SearchStack';
        break;
      case '#saved':
        tabName = 'SavedEventsStack';
        break;
      case '#links':
        tabName = 'LinksStack';
        break;
      case '#settings':
        tabName = 'SettingsStack';
        break;
      default:
        tabName = 'HomeStack';
        break;
    }
    return({tabName, name, params});
  }

  const handleRouteChange = (e) => {
    let baseHash = routeHashes['HomeStack'];
    let route = e.data.state.routes[e.data.state.index];
    switch(route.name) {
      case 'HomeScreen':
        baseHash = "home";
        break;
      case 'SearchScreen':
        baseHash = "search";
        break;
      case 'SavedEventsScreen':
        baseHash = "saved";
        break;
      case 'LinksScreen':
        baseHash = "links";
        break;
      case 'SettingsScreen':
        baseHash = "settings";
        break;
      case 'BrowseEvents':
        baseHash = "browse/" + route.params?.filter.tags.map(t => encodeURIComponent(t)).join(",");
        break;
      case 'EventDetails':
        console.log(route)
        baseHash = "event/" + route.params?.eventId;
        break;
      case 'OrganisationDetails':
        baseHash = "organisation/" + route.params?.organisationId;
        break;
      case 'FacilityDetails':
        baseHash = "facility/" + route.params?.facilityId;
        break;
      case 'LinkDetails':
        baseHash = "link/" + route.params?.linkId;
        break;
    }
    console.log(baseHash)
    //document.location.hash = baseHash;
  }


  export { getNextEventDate, routeHashes, handleRouteChange, getInitialRouteName }