
const getUser = async () => {
  let userData = localStorage.getItem('user');
  return(userData ? JSON.parse(userData) : undefined);
}

const setUser = async (user) => {
  const userData = JSON.stringify(user);
  localStorage.setItem('user', userData);
  return(JSON.parse(userData))
}

const setPermissions = async (permissions) => {
  const permissionsData = JSON.stringify(permissions);
  localStorage.setItem('permissions', permissionsData);
  return(JSON.parse(permissionsData))
}

const getPermissions = async () => {
  let permissionData = localStorage.getItem('permissions');
  return(permissionData ? JSON.parse(permissionData) : { } );
}

const setLanguage = async (language) => {
  localStorage.setItem('language', language);
  return(language)
}

const getLanguage = async () => {
  let language = localStorage.getItem('language');
  return(language || 'sv');
}

const setWhatsNewClosed = async (closed) => {
  localStorage.setItem('whatsNewClosed', closed ? 'true' : 'false');
  return(closed)
}

const getWhatsNewClosed = async () => {
  let whatsNewClosed = localStorage.getItem('whatsNewClosed');
  return(whatsNewClosed);
}

const clearWhatsNewClosed = async () => {
  localStorage.removeItem('whatsNewClosed');
}

const clear = async () => {
  localStorage.clear();
  await localStorage.removeItem('user')
}

const clearPermissions = async () => {
  localStorage.removeItem('permissions');
}

const storage = {
  getUser, 
  setUser,
  clear,
  setPermissions,
  getPermissions,
  clearPermissions,
  setLanguage,
  getLanguage,
  setWhatsNewClosed,
  getWhatsNewClosed,
  clearWhatsNewClosed
}

export { storage }