

/**
 * 
 * @param {*} musts 
 * @param {*} shoulds 
 * @param {*} filters 
 * @param {*} must_nots 
 * @param {*} minimum_should_match 
 * @returns 
 */
const buildQuery = (musts, shoulds, filters, must_nots, sort, minimum_should_match, size, from) => {
  const query = {
    query: {
      bool: {
        must: (musts || []),
        must_not: (must_nots || []),
        should: (shoulds || []),
        minimum_should_match,
        filter: (filters || [])
      }
    },
    size,
    from,
    sort,
  }
  return(query);
}

const buildUserEventsQuery = (user) => {
  const eventsQuery = buildQuery(
    // must
    [
      {
        term: { visible: true },
      }
    ],
    // shoulds 
    [],
    // filter
    [
      {
        range: {
          "date.end.intValue": {
            gt: Date.now().valueOf()
          }
        }
      },
      {
        range: {
          "publishDate.start.intValue": {
            lte: Date.now().valueOf()
          }
        }
      }
    ],
    // must not
    [
      {
        term: { featured: true },
      }
    ],
    // sort
    {
      "date.start.intValue": {
        order: "asc"
      }
    },
    // min should
    1
  );
  if(user.tagsFollowed) {
    for (const tag of user.tagsFollowed) {
      eventsQuery.query.bool.should.push(
        {
          term: {
            "tags.id.keyword": tag
          }
        }
      )
    }
  }
  if(user.organisationsFollowed) {
    for (const tag of user.organisationsFollowed) {
      eventsQuery.query.bool.should.push(
        {
          term: {
            "organisations.id.keyword": tag
          }
        }
      )
    }
  }
  if(user.facilitiesFollowed) {
    for (const tag of user.facilitiesFollowed) {
      eventsQuery.query.bool.should.push(
        {
          term: {
            "facilities.id.keyword": tag
          }
        }
      )
    }
  }
  return(eventsQuery);
} 

const buildOrganisationEventsQuery = (organisationId) => {
  const eventsQuery = buildQuery(
    // must
    [
      {
        term: { visible: true },
      },
      {
        term: {
          "organisations.id.keyword": organisationId
        }
      }
    ],
    // shoulds 
    [],
    // filter
    [
      {
        range: {
          "date.end.intValue": {
            gt: Date.now().valueOf()
          }
        }
      },
      {
        range: {
          "publishDate.start.intValue": {
            lte: Date.now().valueOf()
          }
        }
      }
    ],
    // must not
    [],
    // sort
    {
      "date.start.intValue": {
        order: "asc"
      }
    }
  );
  return(eventsQuery);
}


const buildFacilityEventsQuery = (facilityId) => {
  const eventsQuery = buildQuery(
    // must
    [
      {
        term: { visible: true },
      },
      {
        term: {
          "facilities.id.keyword": facilityId
        }
      }
    ],
    // shoulds 
    [],
    // filter
    [
      {
        range: {
          "date.end.intValue": {
            gt: Date.now().valueOf()
          }
        }
      },
      {
        range: {
          "publishDate.start.intValue": {
            lte: Date.now().valueOf()
          }
        }
      }
    ],
    // must not
    [],
    // sort
    {
      "date.start.intValue": {
        order: "asc"
      }
    }
  );
  return(eventsQuery);
}

export { buildQuery, buildUserEventsQuery, buildOrganisationEventsQuery, buildFacilityEventsQuery }