import React, { useEffect, useState, useRef, useContext } from 'react';
import { View, Dimensions, Image,  ActivityIndicator, Pressable, ScrollView } from 'react-native';

import { restApi, getScaledImageUrl } from '../providers/restApi';
import { useTheme } from '../providers/ThemeContext';
import { FollowButton } from '../components/FollowButton';
import { UpcomingEvents } from '../components/UpcomingEvents';
import { LinkPane } from '../components/LinkPane';
import { TranslatedText, TranslatedData } from '../components/TranslatedText';
import { Carousel } from 'react-responsive-carousel';

import { useTranslatedData } from '../providers/translate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faGlobe, faHeart } from '@fortawesome/free-solid-svg-icons';

import { useParams, useNavigate } from 'react-router-dom';
import { HeaderContext } from '../components/Header';

const OrganisationDetailsScreen = () => {

  const emptyImage = require('../assets/organisation.jpg');

  const navigate = useNavigate();  
  const { id: organisationId } = useParams();

  const headerContext = useContext(HeaderContext);
  const [organisation, setOrganisation] = useState({ description: '', media: [] });
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { width : windowWidth, height : windowHeight } = Dimensions.get('window');
  
  const { translate } = useTranslatedData();
  const theme = useTheme();
  const upcomingList = useRef();

  const leftButtonIcon = (<FontAwesomeIcon icon={faArrowLeft} color={theme.colors.text} size={'xl'} />)

  useEffect(() => {
    (async () => {
      if (organisationId) {
        setIsLoading(true);
        await refresh();
        setIsLoading(false);
      }
    })();
  }, [organisationId])

  const refresh = async () => {
    let organisation = await restApi.get(`/organisations/${organisationId}`);
    setOrganisation(organisation);
    const linksQuery = {
      query: {
        bool: {
          must: [
            {
              term: {
                "organisations.id.keyword": organisationId
              }
            }
          ]
        }
      },
      "_source": ["title", "webSite", "id", "translations"]
    }
    let associatedLinks = await restApi.post(`/links/search`, linksQuery).then(r => r.Hits);
    setLinks(associatedLinks);
    headerContext.setOptions({ title: organisation.title, visible: false })
  }

  const viewEvent = (event) => {
    navigate(`/event/${event.id}`);
  }

  const browseByTag = (tag) => {
    navigate(`/browse/${tag.id}`);
  }

  const goBack = () => {
    navigate(-1);
  }

  const showLinkDetails = (link) => {
    navigate(`/link/${link.id}`);
  }

  let media = (organisation.media || []);
  if (media.length === 0)
    media = [{}];

  if (isLoading) {
    return (
      <>
        <View style={[theme.style.screenHeader, { backgroundColor: 'transparent', position: 'absolute', zIndex: 1, top: 10, left: 0, width: '100%' }]}>
          <Pressable onPress={goBack} style={theme.style.screenHeaderLeftContainer}>
            {leftButtonIcon}
          </Pressable>
        </View>
        <View style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator size="large" color={theme.colors.text}/>
        </View>
      </>
    )
  }

  const loadingIndicator = (
    <View style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <ActivityIndicator size="large" color={theme.colors.text}/>
    </View>
  )

  return (
    <ScrollView style={{ backgroundColor: theme.colors.background }}>

      <View style={[theme.style.screenHeader, { backgroundColor: 'transparent', position: 'absolute', zIndex: 1, top: 10, left: 0, width: '100%' }]}>
        <Pressable onPress={goBack} style={theme.style.screenHeaderLeftContainer}>
          {leftButtonIcon}
        </Pressable>
        <FollowButton 
          type="organisation" 
          id={organisationId} 
          followIcon={<FontAwesomeIcon icon={faHeart} size={'lg'} color={theme.colors.text} style={{ marginRight: 6 }} />}
          unFollowIcon={<FontAwesomeIcon icon={faHeart} size={'lg'} color={theme.colors.text} style={{ marginRight: 6 }} />}
          followTitle={<TranslatedText id="general.follow" />}
          unFollowTitle={<TranslatedText id="general.unfollow" />}
        />
      </View>

      <Carousel
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
      >
        {media.map((m, imageIndex) => ({ key: `${event.id}-${imageIndex}`, ...m })).map((item, index) => {
          return(
            <Image
              key={index}
              source={getScaledImageUrl(item, windowWidth, windowHeight / 2.5, emptyImage)}
              style={{
                width: windowWidth,
                height: windowHeight / 2.5
              }}
              PlaceholderContent={loadingIndicator}
            />
          )            
        })}
      </Carousel>

      <View style={[theme.style.content, { borderBottomRightRadius: 0, borderBottomLeftRadius: 0, flex: 1, marginTop: -20 }]}>
        <View style={[theme.style.contentBlock]}>
          <View style={[theme.style.marginFix, { flexDirection: 'row' }]}>
            <View style={{ flexGrow: 1, flex: 1 }}>
              <TranslatedData style={[theme.style.defaultTitleText]} data={organisation} id="title" />
              {organisation.tags && <View style={{ justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                {(organisation.tags || []).map((tag, index) => {
                  return (
                    <Pressable key={index} onPress={e => browseByTag(tag)} style={[theme.style.tag, theme.style.tagSmall,]} >
                      <TranslatedData key={index} style={[theme.style.tagSmallText]} prefix='#' data={tag} id="name" />
                    </Pressable>
                  )
                })}
              </View>}
            </View>
          </View>
          <div
            dangerouslySetInnerHTML={{ __html: translate(organisation, 'description') }}>
          </div>
          <LinkPane item={organisation}/>
        </View>
        {links.length > 0 && (
          <View style={[theme.style.contentBlock]}>
            <View style={[theme.style.sectionHeader]}>
              <TranslatedText style={theme.style.defaultSectionHeaderText} id="general.related_links" />
            </View>
            {links.map((link, i) => {
              return (
                <Pressable onPress={e => showLinkDetails(link)} key={i} style={{ display: 'flex', flexDirection: 'row', paddingVertical: 5 }}>
                  <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 8 }} size={'lg'} color={theme.colors.link} />
                  <TranslatedData style={[theme.style.defaultLink]} data={link} id="title" />
                </Pressable>
              )
            })}
          </View>
        )}
        <UpcomingEvents viewEvent={viewEvent} organisationId={organisationId} ref={ref => { upcomingList.current = ref; }} />
      </View>

    </ScrollView>
  )
}

export { OrganisationDetailsScreen }