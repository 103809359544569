
import React, { useCallback, useContext, useState } from 'react';
import { Text, Pressable, ActivityIndicator, View } from "react-native"

import { restApi } from '../providers/restApi';
import { useTheme } from '../providers/ThemeContext';
import { SettingsContext } from '../providers/SettingsContext';
import { useTranslation } from '../providers/translate';

const typemap = {
  'event': 'events',
  'tag': 'tags',
  'organisation': 'organisations',
  'facility': 'facilities',
}

const FollowButton = ({ type: _type, followTitle, unFollowTitle, id, onChange, followIcon, unFollowIcon }) => {

  const type = typemap[_type];
  
  const { translate } = useTranslation();
  
  const settings = useContext(SettingsContext);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const { user } = settings.state;

  const toggleFollow = async () => {
    setIsLoading(true);
    if (follows()) {
      let newList = await restApi.delete(`/${type}/${encodeURIComponent(id)}/followers/${user.id}`)
      settings.dispatch({ type: `${type}Followed`, value: newList });
      if (typeof (onChange) == 'function') onChange(-1);
    } else {
      let newList = await restApi.put(`/${type}/${encodeURIComponent(id)}/followers/${user.id}`)
      settings.dispatch({ type: `${type}Followed`, value: newList });
      if (typeof (onChange) == 'function') onChange(1);
    }
    setIsLoading(false);
  }

  const follows = useCallback(() => {
    if(user) {
      const list = user[`${type}Followed`];
      return ((list || []).indexOf(id) !== -1)
    }
  }, [user]);

  const style = { justifyContent: 'center', alignItems: 'center', display: 'flex' }

  return (

    <Pressable
      onPress={e => toggleFollow()}
      onPressIn={e => setIsPressed(true)}
      onPressOut={e => setIsPressed(false)}      
    >
      <View style={[style, theme.style.button, theme.style.buttonSmall, theme.style.dropShadow, (follows() && theme.style.buttonHollow), (isPressed && { backgroundColor: theme.colors.actionPressed })]}>
        {!isLoading && follows() && followIcon}
        {!isLoading && !follows() && unFollowIcon}
        {isLoading && (
          <View style={{ display : 'flex', justifyContent : 'center', alignItems : 'center', padding : 4 }}>
            <ActivityIndicator style={{ marginRight: 4, marginLeft: 4, width : 8, height : 8 }} size="small" color={theme.colors.text} />
          </View>
        )}
        {!isLoading && (
          <Text style={[theme.style.buttonText, follows() && theme.style.buttonHollowText]} >
            {follows() ? (unFollowTitle || translate('general.follows')) : (followTitle || translate('general.follow'))}
          </Text>
        )}
      </View>
    </Pressable>
  )

}

export { FollowButton }