
const darkGray = '#47423e';
const darkerGray = '#34302d';
const white = '#fff';
const background = '#f9f9f9';
const headerWhite = '#e9e9e9';
const black = '#000';

const lightGray = 'rgba(0,0,0,0.05)';
const gray = '#ddd';

const blue = 'rgba(35,97,146,1)';
const turquoise = 'rgba(0,169,224,1)';
const turquoise30 = 'rgba(0,169,224,0.3)';
const turquoise60 = '#81caed';
const green = 'rgba(67,176,42,1)';
const purple = 'rgba(132,50,155,1)';
const pink = 'rgba(208,0,111,1)';
const orange = 'rgba(255,130,0,1)';
const grey = 'rgba(165,156,148,1)';
const grey30 = '#e4e0dd';
const grey60 = 'rgba(165,156,148,0.6)';

const colors = {
  white,
  blue,
  green,
  purple,
  pink,
  orange,
  turquoise,turquoise60,
  grey,grey60,grey30
}

const lightColors = {
  headerTitleColor : purple,
  headerWhite : grey,
  contentBackgroundColor : background,
  safeAreaBackgroundColor : background,
  listItemBorderColor : lightGray,
  listItemTextColor : black,
  subduedTextColor : darkGray,
  eventCardBg : darkerGray,
  background: white,
  border:lightGray,
  action:turquoise,
  actionPressed:turquoise60,
  active:orange,
  text:darkerGray,
  invertedText: white,
  primary: purple,
  sectionHeader: grey60,
  link: turquoise,
  ...colors
}

const darkColors = {
  headerTitleColor : purple,
  headerWhite : grey,
  contentBackgroundColor : background,
  safeAreaBackgroundColor : background,
  listItemBorderColor : lightGray,
  listItemTextColor : black,
  subduedTextColor : darkGray,
  eventCardBg : grey,
  background: white,
  border:lightGray,
  action:orange,
  active:orange,
  text:darkerGray,
  primary: purple,
  link: turquoise,
  ...colors
}

const getColors = (scheme) => {
  return(lightColors);
  return(scheme === 'dark' ? darkColors : lightColors)
}

export { getColors }