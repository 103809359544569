import { useState, useEffect, useReducer } from 'react';
import { Appearance, StyleSheet, View, Image, Text, Dimensions, Pressable } from 'react-native';

import { BottomTabs } from './navigation/BottomTabs';

import { ThemeContext, createTheme } from './providers/ThemeContext';
import { SettingsContext, settingsReducer, defaultSettingsState, settingsInitializer } from './providers/SettingsContext';

import { storage } from './providers/storage';
import { restApi } from './providers/restApi';

import { useTheme } from './providers/ThemeContext';

const Footer = () => {
  const theme = useTheme();
  return(
    <View style={styles.footer}>
      <div style={styles.storeIcons}>
        <a href="https://apps.apple.com/se/app/shmn-nu/id1465219001">
          <Image source={require('./assets/app-store.svg')} resizeMode={'contain'} style={[theme.style.screenHeaderTitleImage]} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.app.p1957GA&pcampaignid=web_share">
          <Image source={require('./assets/google-play.png')} resizeMode={'contain'} style={[theme.style.screenHeaderTitleImage]} />
        </a>
      </div>
    </View>
  );
}

export default function App() {
  
  const colorScheme = Appearance.getColorScheme()
  
  const [theme, setTheme] = useState(createTheme(colorScheme));  
  const [settingsState, dispatchSettingsState] = useReducer(settingsReducer, defaultSettingsState);

  const isTabletOrMobileDevice = Dimensions.get('window').width < 768;

  const [width, setWidth] = useState(isTabletOrMobileDevice ? '100vw' : 768);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }  
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }  
    return "unknown";
  }
  
  const os = getMobileOperatingSystem();
  const [isMobile, setIsMobile] = useState(os !== 'unknown');
  
  useEffect(() => {
    setTheme(createTheme(colorScheme));
  }, [colorScheme])

  useEffect(() => {
    (async () => {
      let tags = await restApi.get('/tags').then(r => r.Hits);
      dispatchSettingsState({ type: 'setTags', value: tags });
      let initialState = await settingsInitializer(settingsState);
      if (!initialState.user || !initialState.user.id) {
        initialState.user = await restApi.put('/users').then(storage.setUser);
      } else {
        initialState.user = await restApi.get(`/users/${initialState.user.id}`).then(storage.setUser);
      }
      dispatchSettingsState({ type: 'setStoredValues', value: initialState });
    })();
  }, [])

  const gotoStore = () => {
    if(os === 'iOS') {
      window.open('itms-apps://itunes.apple.com/app/id1465219001');
    } else {
      window.open('market://details?id=com.app.p1957GA')
    }
  }

  return (
    <ThemeContext.Provider value={theme}>
      <SettingsContext.Provider value={{ state: settingsState, dispatch: dispatchSettingsState }}>
        <View style={styles.mainContainer}>
          <View style={[{...styles.appContainer}, { width }]}>        
            {isMobile && (
              <View style={{padding: 10, backgroundColor: theme.colors.orange}}>
                <Pressable onPress={() => gotoStore()}>
                  <Text style={{ textAlign: 'center', color: theme.colors.white}}>
                    Hämta appen här för en bättre upplevelse.
                  </Text>
                </Pressable>
              </View>
            )}
            <BottomTabs isTabletOrMobileDevice={isTabletOrMobileDevice} />
            <Footer />
          </View>
        </View>
      </SettingsContext.Provider>
    </ThemeContext.Provider>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#ddd',
    alignItems: 'center',
    justifyContent: 'center'
  },
  appContainer: {
    height: '100vh',
    backgroundColor: '#fff',
    borderLeftStyle: 'solid',
    borderLeftWidth: '1px',
    borderLeftColor: '#ccc',
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderRightColor: '#ccc',
    boxShadowColor: '#000',
    boxShadowOffset: { width: 0, height: 0 },
    boxShadowOpacity: 0.1,
    boxShadowRadius: 20,   
    overflow: 'auto' 
  },
  body: {
    width: '100%',
    backgroundColor: '#fff',
  },
  footer: {
    width: '100%',
    backgroundColor: '#fff',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: '#eee',
    textAlign: 'center',
    paddingBottom: 10
  },
  footerParagraph: {
    margin: 5,
    textAlign: 'center',
  },
  storeIcons: { 
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'center', 
    alignItems: 'center', 
    width: '100%',
    marginTop: 10
  }
});
