import React, { useState, useEffect, useContext } from 'react';

import { View, ScrollView, StyleSheet, Text, Pressable, ActivityIndicator } from 'react-native';
import { RefreshControl } from 'react-native';

import { useTheme } from '../providers/ThemeContext';
import { restApi } from '../providers/restApi';
import { EventDate } from '../components/EventDate';
import { EventImage } from '../components/EventImage';
import { FollowButton } from '../components/FollowButton';
import { TranslatedText, TranslatedData } from '../components/TranslatedText';

import { buildQuery } from '../providers/query';
import { getNextEventDate } from '../utils';
import { useTranslation } from '../providers/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag, faCalendarDays, faMapMarkerAlt, faSitemap, faClose } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';

import { HeaderContext } from '../components/Header';

const SearchScreen = () => {

  const initialResults = {
    events: [],
    organisations: [],
    facilities: [],
    tags: []
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useTranslation();

  const headerContext = useContext(HeaderContext);
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [dbSearchResults, setDbSearchResults] = useState(initialResults);
  const [showWhat, setShowWhat] = useState('events');
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [showAllOrganisations, setShowAllOrganisations] = useState(false);
  const [showAllFacilities, setShowAllFacilities] = useState(false);
  const [numTagsFound, setNumTagsFound] = useState(0);
  const [numOrganisationsFound, setNumOrganisationsFound] = useState(0);
  const [numEventsFound, setNumEventsFound] = useState(0);
  const [numFacilitiesFound, setNumFacilitiesFound] = useState(0);

  const styles = StyleSheet.create({
    searchResultsEmpty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20
    }
  })

  useEffect(() => {
    headerContext.setOptions({
      title: translate('general.search')
    })
  }, []);

  useEffect(() => {
    let timeout = setTimeout(async () => {
      setIsSearching(true);
      await search();
      setIsSearching(false);
    }, 500);
    return (() => {
      clearTimeout(timeout);
    })
  }, [searchValue]);

  const mapRecurringEvents = (events, doSort) => {
    let allEvents = events.filter(e => { return (!e.hasRecurringDate) });
    for (const e of events) {
      if (e.hasRecurringDate) {
        let date = getNextEventDate(e);
        allEvents.push({ ...e, date })
      }
    }
    return (!doSort ? allEvents : allEvents.sort((a, b) => {
      return (a.date.start.intValue - b.date.start.intValue)
    }));
  }

  const search = (async () => {
    const v = searchValue.toLowerCase();
    /** others **/
    const eventsQuery = buildQuery(
      [
        {
          term: { visible: true }
        }
      ],
      [
      {
        match_phrase_prefix: {
          name: {
              query : v,
              boost : 2
          }
        }
      },
      {
        match_phrase_prefix: {
          description: {
              query : v,
              boost : 2
          }
        }
      },
      {
        match_phrase_prefix: {
          tagline: {
              query : v,
              boost : 2
          }
        }
      },
      {
        wildcard: {
          "organisations.name.keyword": {
            value: `*${v}*`,
            case_insensitive : true
          }
        }
      },
      {
        wildcard: {
          "name.keyword": {
            value: `*${v}*`,
            case_insensitive : true
          }
        }
      },
      {
        wildcard: {
          "description.keyword": {
            value: `*${v}*`,
            case_insensitive : true
          }
        }
      },
      {
        wildcard: {
          "tagline.keyword": {
            value: `*${v}*`,
            case_insensitive : true
          }
        }
      }],
      [{
        range: {
          "date.end.intValue": {
            gt: Date.now().valueOf()
          }
        }
      },
      {
        range: {
          "publishDate.start.intValue": {
            lte: Date.now().valueOf()
          }
        }
      }
      ]
      , null,
      [
        searchValue === '' ? { "date.start.intValue": { order: "asc" }, "numFollowers": { order: "desc" } } : {  "_score" : { "order" : "desc" }, "date.start.intValue": { order: "asc" } }
      ],
      1,
      searchValue == '' ? (showAllEvents ? 100 : 5) : 50
    );
    const organisationsQuery = buildQuery(
      [
        {
          term: { visible: true }
        }
      ],
      [
      {
        "match_phrase_prefix": {
          "name": {
              "query" : v,
              "boost" : 2
          }
        }
      },
      {
        "match_phrase_prefix": {
          "description": {
              "query" : v,
              "boost" : 2
          }
        }
      },
      {
        wildcard: {
          "name.keyword": {
            value: `*${v}*`,
            case_insensitive : true
          }
        }
      },
      {
        wildcard : {
          "description.keyword": {
            value: `*${v}*`,
            case_insensitive : true
          }
        }
      }
      ], null, null, [
      { "_score" : { "order" : "desc" }, 'numFollowers': { order: 'desc' } }
    ],
      1,
      searchValue == '' ? (showAllOrganisations ? 100 : 5) : 50);
    const facilitiesQuery = buildQuery(
      [
        {
          term: { visible: true }
        }
      ],
      [
      {
        "match_phrase_prefix": {
          "name": {
              "query" : v,
              "boost" : 2
          }
        }
      },
      {
        "match_phrase_prefix": {
          "description": {
              "query" : v,
              "boost" : 2
          }
        }
      },
      {
        wildcard: {
          "description.keyword": {
            value: `*${v}*`,
            case_insensitive : true
          }
        }
      }
      ], null, null, [
      { "_score" : { "order" : "desc" },  'numFollowers': { order: 'desc' } }
    ],
      1,
      searchValue == '' ? (showAllFacilities ? 100 : 5) : 50);
    const tagsQuery = buildQuery(null,
      [{
        wildcard: {
          "title.keyword": {
            value: `*${v}*`,
            case_insensitive : true
          }
        }
      }
      ],
      null,
      null,
      [
        { 'numFollowers': { order: 'desc' } }
      ], 1, searchValue == '' ? (showAllTags ? 100 : 5) : 50);
    let eventsResult = await restApi.post('/events/search', eventsQuery);
    let organisationsResult = await restApi.post('/organisations/search', organisationsQuery);
    let facilitiesResult = await restApi.post('/facilities/search', facilitiesQuery);
    let tagsResult = await restApi.post('/tags/search', tagsQuery);
    setNumEventsFound(eventsResult.NumHits);
    setNumOrganisationsFound(organisationsResult.NumHits);
    setNumFacilitiesFound(facilitiesResult.NumHits);
    setNumTagsFound(tagsResult.NumHits);
    // get buckets for tags
    let tagBuckets = await restApi.post('/events/tags', { tags: tagsResult.Hits.map(t => ({ name: t.name })) }).then(r => r.buckets);
    // get buckets for organisations
    let organisationBuckets = await restApi.post('/events/organisations', { organisations: organisationsResult.Hits.map(o => ({ id: o.id })) }).then(r => r.buckets)
    // get buckets for facilities
    let facilityBuckets = await restApi.post('/events/facilities', { facilities: facilitiesResult.Hits.map(f => ({ id: f.id })) }).then(r => r.buckets)
    // sort
    const secondarySort = (a, b) => {
      return (a.date.start.intValue - b.date.start.intValue);
    }
    // results
    setDbSearchResults({
      events: mapRecurringEvents(eventsResult.Hits, searchValue !== '').sort(secondarySort),
      organisations: organisationsResult.Hits.map(o => ({ ...o, bucket: (organisationBuckets || []).filter(b => b.key === o.id)[0] || { doc_count: 0 } })),
      facilities: facilitiesResult.Hits.map(f => ({ ...f, bucket: (facilityBuckets || []).filter(b => b.key === f.id)[0] || { doc_count: 0 } })),
      tags: tagsResult.Hits.map(t => ({ ...t, bucket: (tagBuckets || []).filter(b => b.key === t.name)[0] || { doc_count: 0 } })),
    })
    setShowSearchResult(searchValue !== '');
  })

  const refresh = async () => {
    setIsRefreshing(true);
    await search()
    setIsRefreshing(false);
  }

  const searchTextChanged = (e) => {
    setSearchValue(e.target.value);
  }

  useEffect(() => {
    if (showAllEvents || showAllOrganisations || showAllOrganisations || showAllTags) {
      refresh();
    }
  }, [showAllEvents, showAllFacilities, showAllOrganisations, showAllTags])

  const browseByTag = (tag) => {
    if (!tag.name) {
      console.error('Invalid tag value: ', tag.name); return;
    }
    navigate('/browse/' + tag.name)
  }

  const navigateToEvent = (event) => {
    navigate(`/event/${event.id}`);
  }

  const navigateToOrganisation = (organisation) => {
    navigate(`/organisation/${organisation.id}`);
  }

  const navigateToFacility = (facility) => {
    navigate(`/facility/${facility.id}`);
  }

  let hasSearchResults = false;
  for (const what of ['events', 'organisations', 'facilities', 'tags']) {
    if (dbSearchResults[what].length > 0) hasSearchResults = true;
  }

  const labels = {
    'events': (<TranslatedText id="general.events" style={[theme.style.upperFirst]} />),
    'organisations': (<TranslatedText id="general.organisations" style={[theme.style.upperFirst]} />),
    'facilities': (<TranslatedText id="general.facilities" style={[theme.style.upperFirst]} />),
    'tags': (<TranslatedText id="general.tags" style={[theme.style.upperFirst]} />)
  }

  const icons = {
    'events': <FontAwesomeIcon icon={faCalendarDays} size={'lg'} color={theme.colors.text} />,
    'organisations': <FontAwesomeIcon icon={faSitemap} size={'lg'} color={theme.colors.text} />,
    'facilities': <FontAwesomeIcon icon={faMapMarkerAlt} size={'lg'} color={theme.colors.text} />,
    'tags': <FontAwesomeIcon icon={faHashtag} size={'lg'} color={theme.colors.text} />,
  }

  const renderItem = (item, index) => {
    if (item.type === 'tag') {
      return (
        <Pressable onPress={e => browseByTag(item)} key={index}>
          <View style={[theme.style.listItem, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }]}>
            <View>
              <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText, { textTransform: 'lowercase' }]} prefix="#" data={item} id="title" />
              <Text style={[theme.style.defaultTextSmall]}>
                {item.bucket.doc_count} <TranslatedText id="general.events" /> | {item.numFollowers} <TranslatedText id="general.followers" />
              </Text>
            </View>
            <FollowButton type='tag' id={item.name} />
          </View>
        </Pressable>
      )
    } else if (item.type === 'event') {
      return (
        <Pressable key={item.id} onPress={e => navigateToEvent(item)} >
          <View key={item.id} style={[theme.style.listItem]}>
            <EventImage event={item} width={65} height={65} />
            <View style={{ flex: 1, flexGrow: 1, paddingHorizontal: 15 }}>
              <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText]} data={item} id="title" />
              {item.tagLine !== '' && (<TranslatedData style={[theme.style.defaultText]} data={item} id='tagLine' />)}
            </View>
            <View>
              <EventDate fullDate={item.date}>{item.date.start.stringValue}</EventDate>
            </View>
          </View>
        </Pressable>
      )
    } else if (item.type === 'organisation') {
      return (
        <Pressable key={item.id} onPress={e => navigateToOrganisation(item)} >
          <View key={item.id} style={[theme.style.listItem, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }]}>
            <EventImage event={item} width={64} height={64} type='organisation' />
            <View style={{ flex: 1, flexGrow: 1, paddingHorizontal: 15 }}>
              <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText]} data={item} id="title" />
              <Text style={[theme.style.defaultTextSmall]}>
                {item.bucket.doc_count} <TranslatedText id="general.events" /> | {item.numFollowers} <TranslatedText id="general.followers" />
              </Text>
            </View>
            <FollowButton type='organisation' id={item.id} />
          </View>
        </Pressable>
      )
    } else if (item.type === 'facility') {
      return (
        <Pressable key={item.id} onPress={e => navigateToFacility(item)} >
          <View key={item.id} style={[theme.style.listItem, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }]}>
            <EventImage event={item} width={64} height={64} type='facility' />
            <View style={{ flex: 1, flexGrow: 1, paddingHorizontal: 15 }}>
              <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText]} data={item} id="title" />
              <Text style={[theme.style.defaultTextSmall]}>
                {item.bucket.doc_count} <TranslatedText id="general.events" /> | {item.numFollowers} <TranslatedText id="general.followers" />
              </Text>
            </View>
            <FollowButton type='facility' id={item.id} />
          </View>
        </Pressable>
      )
    }
  }

  return (
    <>

      <View style={[{ backgroundColor: theme.colors.contentBackgroundColor, flex: 1 }]} >
        <View style={[theme.style.contentBlock]}>
          <View
            style={[{ overflow: 'visible', backgroundColor: 'transparent', borderWidth: 0, borderTopColor: 'transparent', borderBottomColor: 'transparent', padding: 0 }]}
          >
            <View
              style={{ backgroundColor: theme.colors.background, padding: 0, margin: 0, borderRadius: 20, display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent : 'space-between', paddingLeft : 20, paddingRight : 20 }}
            >
              <input
                type="text"
                placeholder={translate('general.search_placeholder')}
                style={{ fontSize: 14, padding: 10, width: '100%', border: 'none', outline: 'none', borderRadius: 20 }}  
                onChange={searchTextChanged}
                value={searchValue}
                autocompletetype='off'
              />
              <button
                style={{ backgroundColor: 'transparent', border: 'none', outline: 'none', padding: 0, margin: 0, cursor: 'pointer' }}
                onClick={e => setSearchValue('')}
              >
                <FontAwesomeIcon icon={faClose} size={'lg'} />
              </button>
            </View>
          </View>
        </View>

        {showSearchResult === false && !isSearching && (
          <View style={[theme.style.contentBlock]}>
            <View style={{ flexDirection: 'row' }}>
              <Pressable onPress={() => setShowWhat('events')}
                style={[theme.style.tabButton, { borderBottomWidth: showWhat == 'events' ? 1 : 0 }]}
              >
                <FontAwesomeIcon icon={faCalendarDays} size={'lg'} color={theme.colors.text} />
                <TranslatedText style={[theme.style.defaultTextSmall, theme.style.upperFirst]} id="general.events" />
              </Pressable>
              <Pressable onPress={() => setShowWhat('tags')}
                style={[theme.style.tabButton, { borderBottomWidth: showWhat == 'tags' ? 1 : 0 }]}
              >
                <FontAwesomeIcon icon={faHashtag} size={'lg'} color={theme.colors.text} />
                <TranslatedText style={[theme.style.defaultTextSmall, theme.style.upperFirst]} id="general.tags" />
              </Pressable>
              <Pressable onPress={() => setShowWhat('facilities')}
                style={[theme.style.tabButton, { borderBottomWidth: showWhat == 'facilities' ? 1 : 0 }]}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} size={'lg'} color={theme.colors.text} />
                <TranslatedText style={[theme.style.defaultTextSmall, theme.style.upperFirst]} id="general.facilities" />
              </Pressable>
              <Pressable onPress={() => setShowWhat('organisations')}
                style={[theme.style.tabButton, { borderBottomWidth: showWhat == 'organisations' ? 1 : 0 }]}>
                <FontAwesomeIcon icon={faSitemap} size={'lg'} color={theme.colors.text} />
                <TranslatedText style={[theme.style.defaultTextSmall, theme.style.upperFirst]} id="general.organisations" />
              </Pressable>
            </View>
          </View>
        )}

        <ScrollView
          refreshControl={
            <RefreshControl
              onRefresh={refresh}
              refreshing={isRefreshing}
            />
          }
        >

          <View style={[theme.style.contentBlock]}>

            {isSearching && (
              <View style={[styles.searchIndicator, { marginTop: 10 }]}>
                <ActivityIndicator size="large" color={theme.colors.text} />
              </View>
            )}

            {!isSearching && showSearchResult && hasSearchResults &&
              ['events', 'tags', 'facilities', 'organisations'].map(what => {
                if (dbSearchResults[what].length > 0) {
                  return (
                    <View key={what} style={[theme.style.content, theme.style.dropShadowIOS, { marginBottom: 15 }]}>
                      <View style={[theme.style.listItem]}>
                        <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
                          <View style={{ width: 28 }}>{icons[what]}</View>
                          {labels[what]}
                        </View>
                      </View>
                      {dbSearchResults[what].map(renderItem)}
                    </View>
                  )
                }
              })}

            {!isSearching && !showSearchResult && dbSearchResults[showWhat].length > 0 && (
              <View style={[theme.style.content, theme.style.dropShadowIOS]}>
                {dbSearchResults[showWhat].map(renderItem)}
                {showWhat === 'events' && !showAllEvents && numEventsFound > 5 && (
                  <View style={theme.style.listItem}>
                    <Pressable onPress={e => { setShowAllEvents(true); }} style={[theme.style.dropShadow, theme.style.button, theme.style.buttonSmall, { marginVertical: 0, flex: 1 }]}>
                      <TranslatedText style={[theme.style.buttonText]} id="general.show_more_events" />
                    </Pressable>
                  </View>
                )}
                {showWhat === 'tags' && !showAllTags && numTagsFound > 5 && (
                  <View style={theme.style.listItem}>
                    <Pressable onPress={e => { setShowAllTags(true); }} style={[theme.style.dropShadow, theme.style.button, theme.style.buttonSmall, { marginVertical: 0, flex: 1 }]}>
                      <TranslatedText style={[theme.style.buttonText]} id="general.show_more_tags" />
                    </Pressable>
                  </View>
                )}
                {showWhat === 'organisations' && !showAllOrganisations && numOrganisationsFound > 5 && (
                  <View style={theme.style.listItem}>
                    <Pressable onPress={e => { setShowAllOrganisations(true); }} style={[theme.style.dropShadow, theme.style.button, theme.style.buttonSmall, { marginVertical: 0, flex: 1 }]}>
                      <TranslatedText style={[theme.style.buttonText]} id="general.show_more_organisations" />
                    </Pressable>
                  </View>
                )}
                {showWhat === 'facilities' && !showAllFacilities && numFacilitiesFound > 5 && (
                  <View style={theme.style.listItem}>
                    <Pressable onPress={e => { setShowAllFacilities(true); }} style={[theme.style.dropShadow, theme.style.button, theme.style.buttonSmall, { marginVertical: 0, flex: 1 }]}>
                      <TranslatedText style={[theme.style.buttonText]} id="general.show_more_facilities" />
                    </Pressable>
                  </View>
                )}
              </View>
            )}

            {dbSearchResults && !hasSearchResults && !isSearching && showSearchResult && (
              <View style={styles.searchResultsEmpty}>
                <TranslatedText style={theme.style.defaultText} id="general.oh_no_no_results" />
              </View>
            )}

            {dbSearchResults && !isSearching && !showSearchResult && (
              <View style={styles.searchResultsEmpty}>
                <TranslatedText style={theme.style.defaultText} id="general.use_search_to_find" />
              </View>
            )}

          </View>
        </ScrollView>
      </View>
    </>
  )

}

export { SearchScreen }