import React, { useContext, useState, useEffect, useCallback } from 'react';
import { View, Pressable, ScrollView, ActivityIndicator, StyleSheet } from 'react-native';
import { MapView } from '../components/MapView';

import { TranslatedText, TranslatedData } from './TranslatedText';

import { EventImage } from './EventImage';
import { EventDate } from './EventDate';
import { AskAboutLocation } from '../modals/AskAboutLocation';
import { SettingsContext } from '../providers/SettingsContext';

import { useTheme } from '../providers/ThemeContext';
import { restApi } from '../providers/restApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { useParams, useNavigate } from 'react-router-dom';

const SavedEvents = () => {

  const navigate = useNavigate();

  const { useMap: useMapByDefault } = useParams();

  const [centerMapAt, setCenterMapAt] = useState({ latitude: 61.30716466554892, longitude: 17.06345804995182 });

  const [useMap, setUseMap] = useState(useMapByDefault);
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);

  const settingsContext = useContext(SettingsContext);
  const locationPermissions = (settingsContext.state.permissions || {}).location;

  const theme = useTheme();

  const { user } = settingsContext.state;
  
  const styles = StyleSheet.create({
    searchResultsEmpty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20
    }
  })

  useEffect(() => {
    if(!useMap && user)
      refresh();
  }, [useMap, user])
  
  const locationChanged = async (location) => {
    setCenterMapAt({
      latitude: location.coords.latitude,
      longitude: location.coords.longitude
    });
  }

  const onRegionChangeComplete = useCallback((location) => {
    console.log('as', location)
    refresh({
      latitude: location.latitude,
      longitude: location.longitude,
      latitudeDelta: location.latitudeDelta,
      longitudeDelta: location.longitudeDelta
    })
  }, []);

  const refresh = async (mapRegion) => {
    setIsLoading(true);
    setEvents([]);
    if(user.eventsFollowed.length > 0) {
      let query = {
        query : {
          bool : {
            should : user.eventsFollowed.map(id => (
              {
                term : {
                  "id.keyword" : id
                }
              }
            )),
            minimum_should_match : 1
          }
        }
      }
      if (mapRegion) {
        const box = {
          geo_bounding_box: {
            location: {
              top_left: {
                lat: mapRegion.latitude + (mapRegion.latitudeDelta / 2),
                lon: mapRegion.longitude - (mapRegion.longitudeDelta / 2)
              },
              bottom_right: {
                lat: mapRegion.latitude - (mapRegion.latitudeDelta / 2),
                lon: mapRegion.longitude + (mapRegion.longitudeDelta / 2)
              }
            }
          }
        }
        query.query.bool.must = [...(query.query.bool.must || []), box]
      }
      let events = await restApi.post('/events/search', query).then(r => r.Hits);
      setEvents(events);
    }
    setIsLoading(false);
  }

  const toggleMap = () => {
    if (typeof (locationPermissions) === 'object') {
      settingsContext.dispatch({ type: 'setPermissions', value: { location: undefined } })
    }
    setUseMap(!useMap);
  }

  const navigateToEvent = (event) => {
    navigate(`/event/${event.id}`);
  }

  return (
    <View>
      <View style={[{ backgroundColor: theme.colors.contentBackgroundColor, flex: 1 }]} >
        <View style={[theme.style.contentBlock]}>
          <View style={{ flexDirection: 'row' }}>
            <Pressable onPress={toggleMap}
              style={[theme.style.tabButton, { borderBottomWidth: !useMap ? 1 : 0 }]}
            >
              <FontAwesomeIcon icon={faList} size={'lg'} color={theme.colors.text} />
              <TranslatedText style={[theme.style.defaultTextSmall]} id="general.show_as_list" />
            </Pressable>
            <Pressable onPress={toggleMap}
              style={[theme.style.tabButton, { borderBottomWidth: useMap ? 1 : 0 }]}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} size={'lg'} color={theme.colors.text} />
              <TranslatedText style={[theme.style.defaultTextSmall]} id="general.show_on_map" />
            </Pressable>
          </View>
        </View>
        <ScrollView
          style={{ backgroundColor: theme.colors.contentBackgroundColor }}          
        >
          <View style={[theme.style.contentBlock]}>
            {useMap &&
              <View style={[theme.style.content, theme.style.dropShadow]}>
                <MapView                
                  centerAt={centerMapAt}
                  events={events}
                  onRegionChangeComplete={onRegionChangeComplete}
                >
                </MapView>
                <AskAboutLocation onLocationChanged={locationChanged} />
              </View>
            }
            <View style={[theme.style.content, theme.style.dropShadow]}>
              {!useMap && events.map((event) => {
                return (
                  <Pressable key={event.id} onPress={e => navigateToEvent(event)} >
                    <View key={event.id} style={[theme.style.listItem]}>
                      <EventImage event={event} width={64} height={64} />
                      <View style={{ flex: 1, flexGrow: 1, paddingHorizontal: 15 }}>
                        <TranslatedData style={[theme.style.defaultText, theme.style.defaultHeaderText]} data={event} id="title" />
                        {event.tagLine !='' && (<TranslatedData style={[theme.style.defaultText]} data={event} id="tagLine" />)}
                      </View>
                      <View>
                        <EventDate fullDate={event.date}>{event.date.start.stringValue}</EventDate>
                      </View>
                    </View>
                  </Pressable>
                );
              })}
            </View>
            {isLoading && (
              <View style={[{ marginTop: 10 }]}>
                <ActivityIndicator size="large" color={theme.colors.text} />
              </View>
            )}
            {events.length == 0 && !isLoading && (
              <View style={styles.searchResultsEmpty}>
                <TranslatedText style={theme.style.defaultText} id="general.no_saved_events" />
              </View>
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  )

}

export { SavedEvents }