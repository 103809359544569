export const sv = {
  general: {
    home: 'SHMN.Nu',
    get_app: 'Hämta appen i App Store eller Google Play',
    save: 'Spara',
    ok: 'Ok',
    cancel: 'Avbryt',
    tom: 't.o.m',
    loading: "Laddar...",
    settings: 'Inställningar',
    search: 'Sök',
    saved_events: 'Sparade evenemang',
    popular_organisations: "Populära verksamheter",
    followed_organisations: "Verksamheter som du följer",
    popular_facilities: "Populära anläggningar",
    followed_facilities: "Anläggningar som du följer",
    followed_tags: 'Taggar som du följer',
    language: 'Språk',
    allow_push_notifications: 'Tillåt pushnotiser',
    allow_position_services: 'Tillåt platstjänster',
    activate_position_services_info: 'Aktivera platstjänster i inställningarna för att använda funktioner som t.ex att visa händelser i din närhet',
    location_services_disabled: 'Du har valt att inte använda platstjänster men du kan alltid ändra dig. Om du aktiverar platstjänster kan du se vad som händer i din närhet.',
    followers: 'följare',
    events: 'evenemang',
    facilities: 'anläggningar',
    organisations: 'verksamheter',
    facility: 'anläggning',
    organisation: 'verksamhet',
    tag: 'tagg',
    tags: 'taggar',
    show_more_events: 'Visa fler evenemang',
    show_more_facilities: 'Visa fler anläggningar',
    show_more_organisations: 'Visa fler verksamheter',
    show_more_tags: 'Visa fler taggar',
    oh_no_no_results: 'Typiskt, hittade inget som matchade din sökning',
    use_search_to_find: 'Använd sökrutan för att hitta fler saker',
    use_search_to_find_links: 'Använd sökrutan för att hitta fler länkar',
    show_as_list: 'Visa lista',
    show_on_map: 'Visa på karta',
    no_saved_events: 'Du har inte sparat några evenemang ännu',
    links: 'Länkar',
    link: 'Länk',
    links_view_info: 'Här hittar du länkar till bra saker som t.ex skolmatsedeln, Be Smart och mer.',
    related_links: 'Relaterade länkar',
    open_link: 'Öppna länk',
    follow: 'Spara',
    unfollow: 'Spara inte',
    starts_at: 'Börjar kl',
    ends_at: 'Slutar kl',
    no_events_nearby: 'Hittade inte några evengemang i närheten',
    keep_updated: 'Håll dig uppdaterad',
    keep_updated_info: 'Vi vill gärna hålla dig uppdaterad med de senaste nyheterna och vill därför skicka notiser till dig när någonting är på gång.',
    dont_want_notifications: 'Jag vill inte ha notiser',
    position_services_info: 'Vill du se händelser i din närhet? Isåfall behöver vi veta din position. Vi lagrar inte denna information och inte heller delar vi med oss av den.',
    do_not_use_my_location: 'Nej tack, använd inte min position',
    show_settings: 'Visa inställningar',
    web: 'Webb',
    fb: 'Facebook',
    ig: 'Instagram',
    follows: 'Följer',
    follow: 'Följ',
    search_placeholder: 'Sök evenemang, anläggningar, taggar m.m',
    choose_language: 'Välj språk',
  },
  events: {
    upcoming: 'Kommande evenemang',
    no_upcoming: 'Inga kommande evenemang.'
  },
  home: {
    flowinfo: "Ditt flöde anpassas efter vilka taggar du väljer att följa. Just nu följer du inte några taggar så här är några förslag på de mest populära taggarna.",
    flowexplore: "Du kan utforska själv på fliken med ett förstoringsglas i menyn längst ner i appen. Där hittar du alla taggar, evenemang, verksamheter och anläggningar."
  },
  error: {
    could_not_open_link: "Kunde inte öppna länk",
    could_not_load_events: "Kunde inte hämta flöde"
  },
  news: {
    whats_new: 'Nyheter i denna version',
    app_is_updated: "Din app har uppdaterats",
    latest: "Nytt i denna version är att du kan välja språk. Gå till inställningar och välj språk. Du kan välja mellan svenska och engelska."
  }
}